@import "../base";
@import "../_includes/mixins";

.woocommerce-error, .woocommerce-info, .woocommerce-message {
  display: none;
}

.single-product, .woocommerce-cart, .woocommerce-checkout {
  .cvz-fm-container {
    display: none;
  }
}

.page-template-check-out {
  .cvz-fm-container {
    display: none;
  }
}

.mkoz-shopping-basket {
  border-left: 1px solid rgba(0, 0, 0, 0.1);
}



.entry-shopping-cart-order {
}
.entry-shopping-cart-intro {

  h5 {
    font-size: 1.5rem;
  }

  .company-block {
    padding-bottom: 20px;
    ul {
      padding-left: 25px;
      list-style: none;
      margin-left: 0;
      padding-left: 0;
      li {
        padding-left: 1em;
        text-indent: -1.1em;
        font-size: 1rem;
        &:before {
          content: '✓';
          color: $corp-color;
          font-weight: 700;
          padding-right: 5px;
        }
      }
    }
  }

  .need-help-block {
    background-color: #fdfdfd;
    padding: 20px;
    hr {
      margin-top: 0rem;
      margin-bottom: 0rem;
    }

    img {
      padding-bottom: 40px;
    }

    a {
      display: block;
      padding: 10px 0px;
      color: #515050;

      &.first {
        padding-top: 40px;
      }

      &:hover {
        color: $corp-color;
      }
    }

    i {
      background: $corp-color;
      color: white;
      width: 30px;
      height: 30px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      border-radius: 5px;
      margin-right: 5px;
    }
  }
}

.entry-product-links-inner {
  ul {
    margin-top: 65px;
    list-style: none;
    padding: 0;
    width: 95%;
    li {
      a {
        color: #000!important;
        i {
          color: $corp-color!important;
        }
      }
    }
  }

}

.product-logo {
  margin: 0 auto;
  margin-bottom: 30px;
}

.product-stock-status {
  background: #FBF9FA;
  font-size: 12px;
  font-weight: 600;
  text-align: center;
  padding: 10px;
  margin-top: 20px;
  border: 1px solid #d8d8d8;
  margin-bottom: 30px;


  strong {
    display: block;
    font-size: 16px;
    margin-bottom: 10px;

    span {
      display: block;
      color: #218c00;
    }
  }
}

.small-spec {
  min-height: 50px;
  height: 85px;
  overflow: hidden;

  @include media-breakpoint-down(sm) {
    height: 68px;
  }

  ul {
    >li {

      &:nth-child(3n) {
        margin-bottom: 200px;
      }
    }
  }

  ul {
    list-style: none;
    padding: 0;

    >  p {
      margin-bottom: 0;
      display: none;
    }

    li {
      font-size: 14px;

      strong {
        display: none;
      }

      &:before {
        content: '>';
        color: $corp-color;
        padding-right: 5px;
      }
    }
  }
}


.cvz-woocommerce-row {
  margin: 0 auto!important;
}

.prdctfltr_columns_3 .prdctfltr_filter {
  width: 100% !important;
}

.prdctfltr_sc_filter {
  margin-top: 35px;
  position: sticky;
  top: 20px;
}

.admin-bar .prdctfltr_sc_filter {
  margin-top: 35px;
  position: sticky;
  top: 40px;
}

.prdctfltr_filter .prdctfltr_regular_title {
  color: $corp-color;
}



.cvz-woocommerce-archive {

  .col-md-3{
    width: 100%;
  }

  .before-shop-loop {
    margin-top: 30px;
    .before-title {
      color: $corp-color;
      font-weight: 600;
      margin-bottom: 30px;
    }

    .before-small-title {
      margin-bottom: 20px;
    }

    p {
      margin-bottom: 20px;
    }

    .before-small-under-title {
      margin-top: 30px;
      margin-bottom: 30px;
      color: $corp-color;
    }

    .before-subcat {
      padding: 0;
      padding-top: 20px;
      display: flex;
      flex-wrap: wrap;

      .subcat-image {
        height: 200px;
      }

      li {
        display: inline-flex;
        flex-basis: calc(100% / 4);
        justify-content: center;

        @include media-breakpoint-down(lg) {
          flex-basis: calc(100% / 2)!important;
          .subcat-image {
            height: 240px!important;
          }
        }

        @include media-breakpoint-down(sm) {
          flex-basis: calc(100% / 2) !important;
        }


        @media (max-width: 480px) {
          .subcat-image {
            height: 170px!important;
          }
        }

        a {
          display: flex;
          flex-direction: column;
          margin: 0 auto;
          text-align: center;

          img {
            display: flex;
            width: 60%;
            margin: 0 auto;
          }

          span {
            font-size: 20px;
            font-weight: 600;
            margin-top: 30px;
          }
        }
      }
    }

    .before-logo {
      padding: 1.5rem 0 1rem;
      border-top: solid 1px #efefef;

      img {
        width: 135px;
      }
    }
  }

  .loop {
    width: 100%;
    padding: 0rem;
    display: flex;
    flex-wrap: wrap;

    margin: 0 0px 30px;

    article {
      flex-basis: calc(100% / 3);
      padding: 15px;

      &:nth-child(3n) {
        padding-right: 0;
      }

      &:nth-child(3n +1) {
        padding-left: 0;
      }

      @include media-breakpoint-down(lg) {
        flex-basis: calc(100% / 2)!important;
      }

      @include media-breakpoint-down(sm) {
        flex-basis: calc(100% / 1)!important;
      }

      >div {
        border: solid 1px #f1f1f1;
        padding: 2rem;
        position: relative;

        @include media-breakpoint-up(md) {
          min-height: 573px;
        }

        &:hover {
          webkit-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
          box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);

          .media-link {
            a {
              background: $corp-color;
            }
          }
        }

        .entry-title {
          font-size: 1.75rem;
          color: $corp-color;
        }

        .media-tag {
          img {
            position: absolute;
            left: 0;
            width: 90px;
          }
        }

        .media-figure {
          align-self: center;
          display: flex;
          height: 233px;
          max-height: 233px;

          img {
            max-height: 100%;
            margin: 0 auto;
          }
        }

        .media-content {
          height: 65px;
          padding-top: 20px;

          //@include media-breakpoint-down(sm) {
          //  height: 105px;
          //}
        }

        .media-spec {
          min-height: 50px;
          height: 64px;
          overflow: hidden;
          margin-bottom: 13px;

          >ul {
            li {
              line-height: 22px!important;
            }
          }

          ul {
            list-style: none;
            padding: 0;
            li {
              font-size: 16px;
              line-height: 10px;
              font-weight: 600;

              ul {
                margin-top: -11px;
                margin-left: 20px;
                li{
                  line-height: 0px!important;
                  margin-top: -15px!important;
                  &:before {
                    display: none;
                  }
                }
              }

              strong {
                display: none;
              }

              &:before {
                content: '>';
                color: $corp-color;
                padding-right: 5px;
              }
            }
          }
        }

        .media-price {
          display: flex;
          padding-bottom: 15px;

          .price-label {
            flex-basis: 60%;
            .entry-price {
              font-size: 2em;
              text-decoration-line: line-through;
              text-decoration-color: red;
              color: #888888;
              line-height: 30px;

              span {
                color: $color-dark-grey;
                font-weight: 600;
              }
            }

            .entry-sale {
              color: #000;
              font-weight: 700;
              font-size: 1.75rem;
            }
          }

          .price-ext {
            font-size: 18px;
            color: $color-grey;
          }
        }

        .media-link {
          a {
            width: 100%;
            font-weight: 500;
            color: #FFFFFF;
            text-align: center;
            background: #333;
            position: static;

            &:after {
              content: "";
              position: absolute;
              top: 0;
              right: 0;
              bottom: 0;
              left: 0;
            }
          }
        }
      }
    }
  }

  .loop-v2 {
    text-align: center;
    article {
      flex-basis: calc(100% / 3);
      > div {
        border: none;
        min-height: initial;
        padding: 0;
        .media-price {
          .price-label {
            flex-basis: 100%!important;
            .entry-price {
              font-size: 18px!important;
              line-height: 10px;
            }

            .entry-sale {
              font-size: 18px!important;
            }
          }
        }

        .media-link {
          position: relative;

          .share-icons {
            opacity: 0;
            position: absolute;
            top: 0px;
            left: 0px;
            right: 0;
            transition: 500ms;
            z-index: 999;

            @include media-breakpoint-down(sm) {
              display: none;
            }

            a {
              background: transparent;
              font-size: 31px;
              padding: 7px 10px;
              border-radius: 6px;
            }

            &.share-wa {
              right: 160px;
              transition: 500ms;
            }

            &.share-mt {
              left: 160px;
              transition: 500ms;
            }
          }
        }

        &:hover {
          box-shadow: none;

          .media-link {
            .button {
              background: $corp-color!important;
              color: #FFFFFF!important;
            }

            .share-icons {
              opacity: 1;

              &.share-wa {
                left: -8%;
                right: inherit;
                transition: 500ms;
                a {
                  color: #25d366;
                }
              }

              &.share-mt {
                left: 91%;
                transition: 500ms;

                a{
                  color: #455a64;
                }
              }
            }

          }
        }
      }

      &:hover {
        box-shadow: none;
      }
    }

    .button {
      width: 80%!important;
      background: #FFFFFF !important;
      border: 1px solid #ccc;
      color: rgb(81, 80, 80)!important;
    }
  }
}

//shopping cart

@include media-breakpoint-up(md) {
  div.offerte-aanvraag {
    height: 823px !important;
  }
}

.shopping-cart {
  margin: 60px 0;

}
.shopping-cart-block {
  height: 1000px!important;

  .shopping-cart-product-preview {
    position: relative;
    .media-tag {
      img {
        width: 100px;
        position: absolute;
        left: 0;
      }
    }

    .product-preview-image {
      margin: 0 auto;
      display: block;
      text-align: center;

      .first {
        width: 60%;
      }

      .second {
        position: absolute;
        top: 22%;
        right: 0;
        width: 40%;

        img {
          width: 90px;
          display: block;
          margin: 0 auto;
        }

        span {
          color: #ee3a42;
          font-weight: 500;
        }
      }
    }
  }
}

.quotation-cart {
  display: none;

  .media-tag {
    img {
      width: 100px;
      position: absolute;
      left: 0;
    }
  }

  .quotation-image {
    margin: 0 auto;
    display: block;
    text-align: center;
  }

  .quotation-review {
    text-align: center;
    span {
      font-size: 12px;
      font-weight: 500;
    }

    .review-stars {
      padding-top: 10px;
      color: $corp-color;
      display: block;
      margin: 0 auto;
      width: 92%;

      i {
        //text-shadow:0px 1px 0 rgb(109, 109, 109),0px -1px 0 rgb(109, 109, 109),1px 0px 0 rgb(109, 109, 109),-1px 0px 0 rgba(109, 109, 109, 0.55),1px 1px 0 rgba(109, 109, 109, 0.55),1px -1px 0 rgba(109, 109, 109, 0.55),-1px 1px 0 rgba(109, 109, 109, 0.55),-1px -1px 0 rgba(109, 109, 109, 0.55);
        //text-shadow:0px 1px 0 rgb(74,74,74),0px -1px 0 rgb(255,255,255),1px 0px 0 rgb(255,255,255),-1px 0px 0 rgb(255,255,255),1px 1px 0 rgb(255,255,255),1px -1px 0 rgb(255,255,255),-1px 1px 0 rgb(255,255,255),-1px -1px 0 rgb(255,255,255);
        //text-shadow:0px 1px 0 rgb(255,194,20),0px -1px 0 rgb(255,194,20),1px 0px 0 rgb(255,194,20),-1px 0px 0 rgb(255,194,20),1px 1px 0 rgb(255,194,20),1px -1px 0 rgb(255,194,20),-1px 1px 0 rgb(255,194,20),-1px -1px 0 rgb(255,194,20);
        text-shadow:0px 2px 1px rgb(74,74,74),0px -1px 0 rgb(247,136,47),1px 0px 0 rgb(247,136,47),-1px 0px 0 rgb(247,136,47),1px 1px 0 rgb(247,136,47),1px -1px 0 rgb(247,136,47),-1px 1px 0 rgb(247,136,47),-1px -1px 0 rgb(247,136,47);
        font-size: 33px;
      }
    }
  }

  .quotation-price-wrapper {
    display: flex;
    flex-direction: column;
    position: relative;
    padding-top: 0px;

    .discount {
      position: absolute;
      right: 0px;
      top: 29px;
      width: 150px;
      height: 50px;
      background: #696969;
      color: #ffffff;
      padding-left: 9px;

      @media (max-width: 991.98px) and (min-width:767px) {
        display: none;
      }

      .discount-text {
        display: block;
        width: 100%;
      }

      .euro {
        display: inline-block;
        font-weight: normal!important;
      }

      p {
        display: inline-block;
      }

      span {
        font-weight: 900;
      }

      &:before {
        content: "";
        position: absolute;
        left: -25px;
        bottom: 0;
        width: 0;
        height: 0;
        border-right: 25px solid #696969;
        border-top: 25px solid transparent;
        border-bottom: 25px solid transparent;
      }
    }

    .entry-price-status {
      span {
        color: #00b900;
        font-weight: 700;
      }
    }
    .entry-price {
      display: flex;
      padding: 20px 0px;
      flex-direction: column;
      padding-bottom: 0px;

      .on-sale {
        padding-left: 0px;
        span {
          &:first-child {
            font-size: 22px;
            text-decoration-line: line-through;
            text-decoration-color: red;
            color: #888888;
          }

          .price {
            padding-left: 19px;
          }
        }
      }

      .btw {
        font-size: 18px;
        text-align: center;
        padding-top: 5px;
        font-weight: 700;
        padding-left: 6px;

      }

      @include media-breakpoint-down(md) {

      }

      >span {
        padding-left: 20px;
      }

      h3 {
        font-size: 36px;
        display: flex;
        font-weight: 600;

        &:last-child {
          color: #000000;
          span {

            &:first-child {
              font-size: 25px;
              font-weight: 700;
            }
          }
        }
        span {
          font-size: 36px;

          span {
            padding-left: 3px;
          }

          &:first-child {

          }
        }

      }
    }

    .entry-price-checks {
      width: 50%;
      ul {
        padding-left: 25px;
        list-style: none;
        margin-left: 0;
        padding-left: 0;
        li {
          padding-left: 1em;
          text-indent: -1.1em;
          font-size: 1rem;
          &:before {
            content: '✓';
            color: $corp-color;
            font-weight: 700;
            padding-right: 5px;
          }
        }
      }
    }
  }
}

.entry-shopping-cart {


  @include media-breakpoint-up(lg) {
    &.sticky-bottom {
      bottom: 0px;
      top: initial;
      position: absolute;
      max-width: 255px;

      .selling-points {
        display: none;
      }

      @include media-breakpoint-up(xl) {
        max-width: 305px;
        width: 305px;
      }

      .discount {
        right: 0 !important;
      }
    }

    &.sticky {
      position: fixed;
      top: 20px;
      max-width: 255px;

      .discount {
        right: 0 !important;
      }

      @include media-breakpoint-up(xl) {
        max-width: 305px;
        width: 305px;
      }
    }
  }

  .entry-shopping-cart-inner {
    background: #fbf9f9;
    border-radius: 0px 0px 5px 5px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
    margin-bottom: 20px;

    .discount-row {
      display: none;
    }

    .shopping-cart-header {
      padding: 10px;
      background: #ee3a42;

      span {
        color: #fff;
        font-size: 1.1em;
        font-weight: 500;
      }

    }

    .shopping-cart-body {
      padding: 10px;
      min-height: 180px;
      border: 1px solid #eaeaea;
      position: relative;

      h3 {
        span {
          color: #000000;
          font-weight: bold;
          font-size: 2.2rem
        }

        .sale-price {
          font-size: 1.75rem;
        }

        &:last-child {
          padding-bottom: 10px;
        }
      }

      .discount {
        display: block;
        float: right;
        text-align: right;
        width: 150px;
        padding-right: 10px;
        background: #ee3a42;
        position: absolute;
        color: #ffff;
        font-size: 16px;
        top: 0;
        font-weight: 600;
        right: 0px;
        border-radius: 0px 0px 0px 40px;

        p {
          margin-bottom: 1px;
        }
      }

      .total-price {
        .on-sale {
          font-size: 2em;
          text-decoration-line: line-through;
          text-decoration-color: red;
          color: #888888;
          line-height: 30px;

          span {
            color: $color-dark-grey;
            font-weight: 600;

          }
        }
      }

      table {
        width: 100%;

        td {
          font-size: 14px;
          padding: 5px 0;
        }

        #price {
          width: 67px;
          font-weight: 600;
          text-align: right;

          p:first-child {
            margin-top: 0px;
          }
        }

        .total {
          border-top: 1px solid rgba(0, 0, 0, 0.1);
          #title {
            font-weight: 600;
          }
        }

        strong {
          display: block;
          font-size: 1rem;
          color: #717070;
        }

        p {
          margin-bottom: 5px;
        }
      }
    }
  }


}

//check out

.check-out {
  margin: 60px 0;
}

//single

.assemble-wrapper {
}

.entry-product-tab-inner {
  display: flex;
  margin-top: 0px;

  @include media-breakpoint-down(md) {
    flex-direction: column;
  }

  .shop_attributes {
    display: flex;
    margin-bottom: 15px;
    margin-left: 0;
    ul:only-of-type {
      li {
        display: inline-flex;
        width: 49%;
        border-top: 1px solid;
      }
    }

    ul {
      padding: 0px;
      margin-bottom: 0px;
      list-style: none;

      li {
        padding: 10px;
        color: black;
      }

      strong {
        display: inline-block;
        width: 38%;
        font-weight: 600;
      }
    }
  }

  .woocommerce-Tabs-panel {
    display: none !important;

    h2 a {
      @include media-breakpoint-down(xs) {
        pointer-events: none;
      }
      &:after {
        font-family: FontAwesome,Open Sans,Arial,sans-serif;
        content: "\F101";
        padding: 0 5px;
      }
    }
  }

  .current {
    display: block !important;
  }

  .price {
    padding-right: 20px;
    flex-basis: 36%;
    @include media-breakpoint-down(md) {
      flex-basis: 100%;
      order: 2;
    }

    span {
      font-weight: 600;
    }

    p {
      font-size: 13px;
    }
  }

  .woocommerce-tabs {
    padding-top: 10px;
    font-size: 15px;
    width: 100%;

    @include media-breakpoint-down(md) {
      order: 1;
    }

    @include media-breakpoint-down(lg) {
      padding-top: 20px;
    }

    th {
      font-weight: 500;
    }

    h2 {
      font-size: 16px;
      font-weight: 500;
      color: $corp-color;
    }

    .wc-tabs {
      display: none;
    }
  }
}

.cvz-woocommerce-single {
  margin-top: 2rem;
  margin-bottom: 2rem;

  border-radius: $border-radius-default;
  overflow: hidden;

  .onsale {
    display: none;
  }

  @include media-breakpoint-up(md) {
    display: flex;
  }

  @include media-breakpoint-down(md) {
    flex-direction: column;
  }

  .arrow-down {
    background: #107c8c;
    position: absolute;
    width: 100%;
    padding: 10px 0;
    bottom: 0;
    left: 0;

    &:after {
      border-left: 15px solid transparent;
      border-right: 15px solid transparent;
      border-top: 15px solid #fbf9f9;
    }
  }

  .collapse-wrapper {
    .collapse-head {
      background: #efefef;
      position: relative;
      cursor: pointer;
      border-radius: $border-radius-default;

      h2 {
        padding: 6px 20px;
        font-size: 18px;
        color: #515050!important;
      }

      &:after {
        font-family: FontAwesome;
        content: "\F103";
        position: absolute;
        color: #515050;
        right: 9px;
        top: -6px;
        font-size: 30px;
      }
    }

    .content {
      padding: 0 20px;

      .review-stars i {
        font-size: 26px!important;
      }
    }
  }

  .shop_attributes_small {
    width: 100%;
    height: 200px;
    overflow: hidden;
    display: flex;
    flex-wrap: wrap;

    p {
      display: none;
    }

    ul {
      width: 100%;
      padding: 0;
      margin: 0;
      list-style: none;

      >p {
        margin-bottom: 13px;
        display: none;
      }

      >li {
        display: flex;
        margin-bottom: 15px;

        &:nth-child(5) {
          margin-bottom: 70px;
        }

        strong {
          flex-basis: 50%;
          font-weight: 500;
        }

        li {
          margin-bottom: 0px;
        }
      }
    }
  }

  > .col-md-8 {
    @include media-breakpoint-down(sm) {
      margin-bottom: 40px;
    }
  }

  > .col-md-8, .col-md-4 {

    @include media-breakpoint-up(sm) {
      padding: 0 32px;
    }

    @include media-breakpoint-down(md) {
      flex: 0 0 100%;
      max-width: 100%;
    }
  }

  .single-product-details {
    display: flex;
    flex-direction: column;

    .entry-title {

      .head-title {
        margin-bottom: 0px;
        font-size: 1.9rem;
      }

      &.upgrade {
      }

      h2 {
        color: $corp-color;
      }

      span {
        font-size: 1.3rem;
        font-weight: 600;
        color: $color-dark-grey;
      }
    }

    .product-order-mode {
      h4 {
        padding-left: 20px;
        color: $corp-color;
      }
    }

    .entry-row {
      display: flex;
      position: relative;
      margin-bottom: 10px;
      margin-top: 20px;

      border-radius: $border-radius-default;
      overflow: hidden;

      flex-direction: row;
      flex-wrap: wrap;
      flex-flow: row wrap;
      align-content: flex-end;

      @include media-breakpoint-down(md) {
        flex-direction: column;
      }

      .entry-images {
        @include media-breakpoint-up(md) {
          flex-basis: 32%;
          padding-right: 20px;

        }

        .media-tag {
          img {
            width: 100px;
            position: absolute;
            left: 0;
          }
        }

        .entry-product-review {
          margin-top: -30px;
          text-align: center;
          span {
            font-size: 12px;
            font-weight: 500;
          }

          .review-stars {
            padding-top: 10px;
            color: $corp-color;
            display: block;
            margin: 0 auto;
            width: 92%;

            i {
              //text-shadow:0px 1px 0 rgb(109, 109, 109),0px -1px 0 rgb(109, 109, 109),1px 0px 0 rgb(109, 109, 109),-1px 0px 0 rgba(109, 109, 109, 0.55),1px 1px 0 rgba(109, 109, 109, 0.55),1px -1px 0 rgba(109, 109, 109, 0.55),-1px 1px 0 rgba(109, 109, 109, 0.55),-1px -1px 0 rgba(109, 109, 109, 0.55);
              //text-shadow:0px 1px 0 rgb(74,74,74),0px -1px 0 rgb(255,255,255),1px 0px 0 rgb(255,255,255),-1px 0px 0 rgb(255,255,255),1px 1px 0 rgb(255,255,255),1px -1px 0 rgb(255,255,255),-1px 1px 0 rgb(255,255,255),-1px -1px 0 rgb(255,255,255);
              //text-shadow:0px 1px 0 rgb(255,194,20),0px -1px 0 rgb(255,194,20),1px 0px 0 rgb(255,194,20),-1px 0px 0 rgb(255,194,20),1px 1px 0 rgb(255,194,20),1px -1px 0 rgb(255,194,20),-1px 1px 0 rgb(255,194,20),-1px -1px 0 rgb(255,194,20);
              text-shadow:0px 2px 1px rgb(74,74,74),0px -1px 0 rgb(247,136,47),1px 0px 0 rgb(247,136,47),-1px 0px 0 rgb(247,136,47),1px 1px 0 rgb(247,136,47),1px -1px 0 rgb(247,136,47),-1px 1px 0 rgb(247,136,47),-1px -1px 0 rgb(247,136,47);
              font-size: 33px;
            }
          }
        }

        @include media-breakpoint-down(md) {
          .woocommerce-product-gallery__wrapper {
            img {
              display: block;
              margin: 0 auto;
            }
          }
        }

        .entry-share {
          margin-top: 20px;
          text-align: center;
          span {
            font-style: italic;
            font-weight: 600;
            font-size: 16px;
          }

          .share-icons {
            a {
              font-size: 30px;
              color: #333;
            }
          }
        }
      }

      .entry-summary {
        display: flex;
        flex-direction: column;
        flex-basis: 100%;
        @include media-breakpoint-up(md) {
          flex-basis: 68%;
        }

        .entry-price-wrapper {
          display: flex;
          flex-direction: column;
          position: relative;

          .discount {
            position: absolute;
            right: 0px;
            top: 51px;
            width: 150px;
            height: 50px;
            background: #696969;
            color: #ffffff;
            padding-left: 9px;

            @media (max-width: 480px) {
              position: relative;
              margin-left: 40%;
              overflow: visible;
              top: 10px;
              display: none;
            }

            .discount-text {
              display: block;
              width: 100%;
            }

            .euro {
              display: inline-block;
              font-weight: normal!important;
            }

            p {
              display: inline-block;
            }

            span {
              font-weight: 900;
            }

            &:before {
              content: "";
              position: absolute;
              left: -25px;
              bottom: 0;
              width: 0;
              height: 0;
              border-right: 25px solid #696969;
              border-top: 25px solid transparent;
              border-bottom: 25px solid transparent;
            }
          }

          .entry-price-status {
            span {
              color: #00b900;
              font-weight: 700;
            }
          }
          .entry-price {
            display: flex;
            padding: 20px 0px;
            flex-direction: column;
            padding-bottom: 0px;

            .on-sale {
              padding-left: 0px;
              span {
                &:first-child {
                  font-size: 22px;
                  text-decoration-line: line-through;
                  text-decoration-color: red;
                  color: #888888;
                }

                .price {
                  padding-left: 19px;
                }
              }
            }

            .btw {
              font-size: 18px;
              text-align: center;
              padding-top: 5px;
              font-weight: 700;
              padding-left: 6px;

            }

            @include media-breakpoint-down(md) {

            }

            >span {
              padding-left: 20px;
            }

            h3 {
              font-size: 36px;
              display: flex;
              font-weight: 600;

              &:last-child {
                color: #000000;
                span {

                  &:first-child {
                    font-size: 25px;
                    font-weight: 700;
                  }
                }
              }
              span {
                font-size: 36px;

                span {
                  padding-left: 3px;
                }

                &:first-child {

                }
              }

            }
          }

          .entry-price-checks {
            width: 50%;
            ul {
              padding-left: 25px;
              list-style: none;
              margin-left: 0;
              padding-left: 0;
              li {
                padding-left: 1em;
                text-indent: -1.1em;
                font-size: 1rem;
                &:before {
                  content: '✓';
                  color: $corp-color;
                  font-weight: 700;
                  padding-right: 5px;
                }
              }
            }
          }
        }

        .entry-price-footer {
          position: relative;
          .share-icons {
            position: absolute;
            right: 0;
            top: 36px;
            font-size: 20px;

            @media (max-width: 480px) {
              display: none;
            }

            a {
              border: 1px solid;
              text-align: center;
              border-radius: 6px;
              width: 34px;
              display: inline-block;
              height: 34px;

              &:first-child {
                border-color: #25d366;
                color: #25d366;
              }
            }
          }

          .share-icons-order {
            top: -120px;
          }

          .cvz-btn{
            text-transform: inherit!important;
            padding-left: 0.5em!important;
            padding-right: 0.8em!important;
            line-height: 38px!important;
            height: 38px;
            padding-top: 0!important;
            padding-bottom: 0!important;

            @media (max-width: 480px) {
              padding-left: 0.3em!important;
              padding-right: 0.6em!important;
            }

            i {
              padding-right: 6px;
            }
          }
          span,button {
            font-size: 13px;
            display: inline-block;
            text-transform: inherit!important;

          }

          form {
            display: inline-block;
          }

          .quotation-call {
            background: $corp-secondary-color;
            margin-left: 15px;
            border-radius: $border-radius-default;


            @include media-breakpoint-down(md) {
              font-size: 0.7rem;
            }


            &:hover {
              opacity: 0.9;
            }
          }
        }

        .entry-stock-status {
          background: #f4f4f4;
          border-left: 3px solid $corp-color;
          margin-bottom: 20px;

          @include media-breakpoint-down(sm) {
            margin-top: 10px;
          }

          span {
            font-size: 13px;
            padding-left: 10px;

            @include media-breakpoint-down(sm) {
              font-size: 12px;
            }

            i {
              color: $corp-color;
            }
          }

        }

        .product-logo {
          img {
            float: right;
            max-width: 180px;
          }
        }

        .entry-summary-inner {
          strong {
            font-weight: 500;
          }

          span {
            overflow: hidden;
            height: 241px;
            display: block;
          }

          font-size: 16px;
        }

      }

      .entry-content {
        font-size: 16px;
        width: 100%;
        .entry-product-links-inner {
          padding-bottom: 20px;
          margin-top: -10px;
          ul {
            display: flex;
            list-style: none;

            padding: 0px;
            position: absolute;

            li {
              &:after {
                content: '|';
                padding: 0 10px;
                color: $corp-color;
              }

              &:last-child {
                &:after {
                  content: ''
                }
              }

              a {
              }
            }
          }
        }
      }
    }

    .cross-sells {
      > h2 {
        display: none;
      }
    }

    .entry-thermo, .entry-service, .entry-assemble {
      display: flex;
      margin-bottom: 1rem;
      flex-wrap: wrap;

      @include media-breakpoint-down(md) {
        flex-direction: column;
      }

      > div {
        flex-basis: calc((100% - 15px) / 2);
        flex-direction: column;

        margin: 5px;
        margin-bottom: 20px;

        &:hover {
          webkit-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
          box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
        }

        &:first-child {
          margin-left: 0;
        }

        &:nth-child(3) {
          margin-right: 0;
        }

        .cart {
          padding: 1rem;
          border: solid 1px #f1f1f1;
          cursor: pointer;
          height: 100%;

          @include media-breakpoint-down(sm) {
            padding: 1rem 1rem 1rem 1rem;
          }

          &.selected {
            border: solid 1px #f17e00;
            //background-color: #fff9f2;
            background-color: #FBF9FA;

            webkit-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
            box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
          }

          &:hover {
            .cart-inner {
              > span {
                background: $corp-color;
              }
            }
          }

          h5 {
            min-height: 54px;
            font-size: 1.1rem;
            color: $color-dark-grey;
          }

          ul {
            margin: 0px;
            padding: 0 20px;
            font-size: 14px;
            list-style: none;
            padding: 0;
            height: 100px;

              li {
                &:before {
                  font-weight: 600;
                  content: '>';
                  color: $corp-color;
                  padding-right: 5px;
                }
              }

          }

          img {
            height: 120px;
            margin-bottom: 20px;
          }

          .cart-inner {
            display: flex;
            margin-top: 10px;
            > span {
              background: #333;
              font-size: 14px;
              font-weight: 400;
              color: #fff;
              flex-basis: 56%;
              border-radius: $border-radius-default;

              padding: 12px 0px;
              text-align: center;

              &:after {
                font-family: FontAwesome, "Open Sans", Arial, sans-serif;
                content: "\F101";
                padding: 0px 5px;
              }
            }

            p {
              align-self: center;
              margin: 0;
              text-align: right;
              flex-basis: 44%;
              font-size: 14px;

              > span {
                color: $corp-color;
              }
            }
          }

          button {
            cursor: pointer;
            margin: 0px;
            padding: 3rem 1rem 3rem 1rem;
            background: transparent;
            border: 0;
            text-align: inherit;

          }
        }
      }
    }

    .related {
      > h2 {
        display: none;
        color: $corp-color;
        font-size: 1.25rem;
      }

      ul {
        li {
          padding: 20px;
          border-radius: $border-radius-default;
          overflow: hidden;
          webkit-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
          box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
          @include media-breakpoint-up(md) {
            margin-bottom: 0;
          }

          h2 {
            color: $color-dark-grey;
          }

          .amount {
            color: $corp-color;
          }

          .button {
            background: #333;
            color: #FFFFFF;
          }
        }
      }
    }
  }

  .entry-shopping-cart  button.button {
    text-align: left;
    i {
      margin-right: 10px;
    }
  }

  .entry-shopping-cart {

    .product-logo {
      img {
        width: 100%;
        @include media-breakpoint-down(md) {
          max-width: 280px;
          margin: 10px auto;
          display: block;
        }
      }
    }

    @include media-breakpoint-up(lg) {
      &.sticky-bottom {
        bottom: 0px;
        top: initial;
        position: absolute;
        max-width: 255px;

        @include media-breakpoint-up(xl) {
          max-width: 305px;
        }

        .discount {
          right: 0 !important;
        }
      }

      &.sticky {
        position: fixed;
        top: 20px;
        max-width: 255px;

        .discount {
          right: 0 !important;
        }

        .selling-points {
          display: none;
        }

        @include media-breakpoint-up(xl) {
          max-width: 305px;
        }
      }
    }

    .entry-shopping-cart-inner {
      background: #fbf9f9;
      border-radius: 0px 0px 5px 5px;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
      margin-bottom: 20px;

      .shopping-cart-header {
        padding: 10px;
        background: #ee3a42;

        span {
          color: #fff;
          font-size: 1.1em;
          font-weight: 500;
        }

      }

      .shopping-cart-body {
        padding: 10px;
        min-height: 180px;
        border: 1px solid #eaeaea;

        h3 {
          font-size: 1.75rem;

          span {
            color: #000000;
            font-weight: bold;
          }

          &:last-child {
            padding-bottom: 10px;
          }
        }

        .discount {
          display: block;
          float: right;
          text-align: right;
          width: 150px;
          padding-right: 10px;
          background: #ee3a42;
          position: absolute;
          color: #ffff;
          font-size: 16px;
          top: 0;
          font-weight: 600;
          right: 0px;
          border-radius: 0px 0px 0px 40px;

          p {
            display: inline;
            margin-bottom: 1px;
          }
        }

        .total-price {
          .on-sale {
            font-size: 2em;
            text-decoration-line: line-through;
            text-decoration-color: red;
            color: #888888;
            line-height: 30px;

            span {
              color: $color-dark-grey;
              font-weight: 600;

            }
          }
        }

        table {
          width: 100%;

          td {
            font-size: 14px;
            padding: 5px 0;
          }

          #price {
            width: 67px;
            font-weight: 600;
            text-align: right;

            p:first-child {
              margin-top: 0px;
            }
          }

          .total {
            border-top: 1px solid rgba(0, 0, 0, 0.1);
            #title {
              font-weight: 600;
            }
          }

          strong {
            display: block;
            font-size: 1rem;
            color: #717070;
          }

          p {
            margin-bottom: 5px;
          }
        }
      }
    }

    .quantity {
      display: none;
    }

    .selling-points {
      padding: 30px 0;
    }

    button {
      width: 100%;
      font-weight: 500;

      border-radius: 0px;
      border-radius: $border-radius-default;

      &.single_add_to_cart_button {
        background: $corp-color;
        margin-bottom: 5px;

        &:hover {
          background: $corp-color;
          opacity: 0.8;
        }
      }

      &.order-button {
        background: #333;

        &:hover {
          background: #333;
          opacity: 0.8;
        }
      }
    }

    .maintenance-call {
      margin-top: 5px;
      display: none;
      .maintenance-call-text {
        background: #ee3a42;
        color: #FFFFFF;
        font-weight: 600;
        font-size: 19px;

        padding: 11px 20px;
        border-radius: $border-radius-default;
      }

      a {
        background: #f7882f;
        color: #ffffff;
        float: right;
        padding: 5px 10px;
        font-size: 14px;
        font-weight: 600;

        &:hover {
          opacity: 0.8;
        }
      }
    }
  }

  .add-to-shopping-cart {
    display: none;

    button {
      width: 100%;
      font-weight: 500;

      &.single_add_to_cart_button {
        background: $corp-color;
        margin-bottom: 5px;

        &:hover {
          background: $corp-color;
          opacity: 0.8;
        }
      }

      &.order-button {
        background: #333;
        border-radius: 0px;

        border-radius: $border-radius-default;

        &:hover {
          background: #333;
          opacity: 0.8;
        }
      }
    }
  }
}
