body {
  &.modal-active {
    overflow: hidden;
  }

  overflow-x: hidden;
}

.modal-active {

}

.modal {
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 999999;

  &::before {
    position: absolute;
    content: ' ';
    background:rgba(0,0,0,.8);
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 1;
  }

  .modal-inner {
    display: flex;
    flex-direction: column;
    position: relative;

    @include media-breakpoint-up(md) {
      width: 1260px;
    }

    background-color: #ffffff;
    z-index: 2;

    max-width: 1260px;
    height: 700px;
    overflow: hidden;
    overflow-y: scroll;
    margin: auto;
    position: absolute;
    top: 0; left: 0; bottom: 0; right: 0;
    @include cvz_border_radius();

    @media (max-width: 500px) {
      height: 100%;
    }

    .modal-content {
      padding: 0 50px;

      @media (max-width: 500px) {
        padding: 0 15px;
      }

      h2 {
        font-weight: 300;
        padding-top: 10px;
        padding-bottom: 20px;
        color: $corp-color;
      }
    }

    .shop_attributes {
      padding: 0px;
      flex-direction: column;
      ul {
        width: 100%;
        >p {
          margin-bottom: 0;
        }

        li {
          width: 100% !important;
          &:nth-child(odd) {
            background: #ececec;
          }

          &:nth-child(even) {
            background: white;
          }
        }
      }

    }

    >h2 {
      padding: 0 40px;
      font-size: 1.5rem;
      margin-bottom: 20px;
    }
  }

  .modal-top {
    display: flex;
    top: 0;
    width: 100%;
    left: 0;
    background: #f4f4f4;
    margin-bottom: 20px;

    .modal-icon {
      padding: 10px 10px 10px 20px;

      span {
        padding: 7px;
        background: $corp-color;
        border-radius: 30px;
        display: flex;
        i {
          color: #ffffff;
          width: 18px;
          height: 18px;
          text-align: center;
        }
      }
    }

    .modal-title {
      flex-basis: 100%;
      h2{
        color: #31211f;
        font-weight: 700;
        padding: 15px 10px;
        font-size: 22px;
        margin: 0;
      }
    }

    .modal-close {
      align-self: center;
      justify-self: center;

      i {
        padding: 10px;
        padding-right: 30px;
      }
    }
  }

  .modal-header {
    display: flex;
    align-items: center;

    background-color: $corp-secondary-color;
    color: #ffffff;
    padding: 1rem;

    border-top-right-radius: 5px;

    h6 {
      margin: 0;
      padding: 0;
      font-size: 0.875rem;

      font-family: $font-family-base;
      font-weight: 600;
      text-transform: uppercase;
      letter-spacing: 0.8px;
    }

    a {
      color: #ffffff;
      line-height: 1;

      @include cvz_transition_link;

      &:hover {
        color: rgba(#ffffff, 0.6);
      }
    }

    .modal-close {
      margin-left: auto;

      i {
        font-size: 20px;
        line-height: 1;
      }
    }
  }

  input {
    outline: 0;
  }

  .modal-body {
    padding: 1rem 1rem 0 1rem;
  }

  .modal-footer {
    display: flex;
    align-items: center;

    padding: 1rem;
  }
}

.modal-hide {
  display: none;
}

.mcw-modal {
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: auto;

  height: 100%;
  width: 96%;

  top: 0;
  right: -100%;
  z-index: 99999;

  background: #ffffff;
  box-shadow: 0 0 105px 35px rgba(0,0,0,.6);

  .mcw-modal-container {
    .mcw-modal-loading {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: white;
      text-align: center;
    }

    .mcw-modal-top {
      position: absolute;
      top: 0;
      left: 0;

      width: 100%;
      padding: 10px 20px;
      color: #d7dadc;
      border-bottom: 1px solid #d7dadc;

      span {
        cursor: pointer;

        @include media-breakpoint-down(md) {
          padding: 0 5px;
          font-size: 1rem;
        }
      }

      .modal-top-right {
        float: right;
        display: none;

        span {
          @include media-breakpoint-up(md) {
            padding: 0 40px;
          }
        }
      }

    }

    .mcw-modal-content {
      display: block;
      position: absolute;
      top: 90px;
      left: 0;
      right: 0;
    }
  }
}

//
//#modal-container {
//  position:fixed;
//  display:table;
//  height:100%;
//  width:100%;
//  top:0;
//  left:0;
//  z-index:999;
//
//  transform: scale(1);
//
//  .modal-background {
//    background: rgba(0,0,0,.0);
//    animation: fadeIn .5s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;
//
//    .modal {
//      height:75px;
//      width:75px;
//      border-radius:75px;
//      overflow:hidden;
//      background-color: #ffffff;
//
//      //h2, p {
//      //  opacity:0;
//      //  position:relative;
//      //  animation: modalContentFadeIn .5s 1.4s linear forwards;
//      //}
//    }
//  }
//
//  .out {
//    animation: quickScaleDown 0s .5s linear forwards;
//
//    .modal-background {
//      animation: fadeOut .5s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;
//
//      .modal {
//        animation: modalFadeOut .5s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;
//
//        h2, p {
//          animation: modalContentFadeOut .5s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;
//        }
//
//        .modal-svg {
//          //rect {
//          //  animation: modalFadeOut .5s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;
//          //}
//        }
//      }
//    }
//  }
//}
//
//.modal-background {
//  display:table-cell;
//  background:rgba(0,0,0,.8);
//  text-align:center;
//  vertical-align:middle;
//
//  .modal {
//    background:white;
//    padding:50px;
//    display:inline-block;
//    border-radius:3px;
//    font-weight:300;
//    position:relative;
//
//    h2 {
//      font-size:25px;
//      line-height:25px;
//      margin-bottom:15px;
//    }
//
//    p {
//      font-size:18px;
//      line-height:22px;
//    }
//
//    img {
//      width: 40px;
//      position:absolute;
//
//      left: 50%;
//      top: 50%;
//      transform: translate(-50%, -50%);
//      filter: grayscale(1);
//      animation:grayscale 1s;
//      -moz-animation:grayscale 1s infinite;
//      -webkit-animation:grayscale 1s infinite;
//    }
//
//    .modal-svg {
//      position:absolute;
//      top:0;
//      left:0;
//      height:100%;
//      width:100%;
//      border-radius:3px;
//
//      //rect {
//      //  stroke: #fff;
//      //  stroke-width: 2px;
//      //  stroke-dasharray: 778;
//      //  stroke-dashoffset: 778;
//      //}
//    }
//  }
//}
//
//
//@keyframes fadeIn {
//  0% {
//    background:rgba(0,0,0,.0);
//  }
//  100% {
//    background:rgba(0,0,0,.7);
//  }
//}
//
//@keyframes fadeOut {
//  0% {
//    background:rgba(0,0,0,.7);
//  }
//  100% {
//    background:rgba(0,0,0,.0);
//  }
//}
//
//@keyframes scaleUp {
//  0% {
//    transform:scale(.8) translateY(1000px);
//    opacity:0;
//  }
//  100% {
//    transform:scale(1) translateY(0px);
//    opacity:1;
//  }
//}
//
//@keyframes scaleDown {
//  0% {
//    transform:scale(1) translateY(0px);
//    opacity:1;
//  }
//  100% {
//    transform:scale(.8) translateY(1000px);
//    opacity:0;
//  }
//}
//
//@keyframes scaleBack {
//  0% {
//    transform:scale(1);
//  }
//  100% {
//    transform:scale(.85);
//  }
//}
//
//@keyframes scaleForward {
//  0% {
//    transform:scale(.85);
//  }
//  100% {
//    transform:scale(1);
//  }
//}
//
//@keyframes quickScaleDown {
//  0% {
//    transform:scale(1);
//  }
//  99.9% {
//    transform:scale(1);
//  }
//  100% {
//    transform:scale(0);
//  }
//}
//
//@keyframes modalFadeIn {
//  0% {
//    background-color:transparent;
//  }
//  100% {
//    background-color:white;
//  }
//}
//
//@keyframes modalFadeOut {
//  0% {
//    background-color:white;
//  }
//  100% {
//    background-color:transparent;
//  }
//}
//
//@keyframes modalContentFadeIn {
//  0% {
//    opacity:0;
//    top:-20px;
//  }
//  100% {
//    opacity:1;
//    top:0;
//  }
//}
//
//@keyframes modalContentFadeOut {
//  0% {
//    opacity:1;
//    top:0px;
//  }
//  100% {
//    opacity:0;
//    top:-20px;
//  }
//}
//
//@keyframes bondJamesBond {
//  0% {
//    transform:translateX(1000px);
//  }
//  80% {
//    transform:translateX(0px);
//    border-radius:75px;
//    height:75px;
//    width:75px;
//  }
//  //90% {
//  //  border-radius:3px;
//  //  height:182px;
//  //  width:247px;
//  //}
//  //100% {
//  //  border-radius:3px;
//  //  height:162px;
//  //  width:227px;
//  //}
//}
//
//@keyframes slowFade {
//  0% {
//    opacity:1;
//  }
//  99.9% {
//    opacity:0;
//    transform:scale(1);
//  }
//  100% {
//    transform:scale(0);
//  }
//}