$corp-color: #f7882f;
$corp-secondary-color: #eceef0;
$corp-tertiary-color: #ee3a43;


$corp-font-family: 'Avenir Next LT Pro';

$color-dark-grey: #565555;
$color-grey: #e1e0e2;
$color-black: #333;


$navigation-link-color: #000000;
$navigation-link-hover-color: $color-dark-grey;

$border-radius-default: 0 5px 0 5px;

$colors: (
        'orange': $corp-color,
        'dark-blue': $corp-secondary-color,
        'dark-grey': $color-dark-grey,
        'grey': $color-grey,
        'blue': $corp-color,
        'red': $corp-tertiary-color,
        'black': $color-black
);
