@import "base";
@import "_includes/config";
@import "_components/beaver-builder";
@import "_components/buttons";
@import "_components/header";
@import "_components/footer";
@import "_components/footer-bottom";
@import "_components/modal";
@import "_components/woocommerce";
@import "_components/maintenance-wizard";
@import "_components/maintenance-planner";
@import "_components/my-cvzeker";
@import "_components/transitions";

html {
  -webkit-font-smoothing: antialiased;
}

header .row {
  margin: 0;
}

header .header-wrapper {
  padding: 0;
}

.modal {
  z-index: 9999999999999999;
}

.search .cvz-woocommerce-archive .loop article>div .media-content {
  height: 100%;
}

.fl-module-cvz-boiler-wizard .cvz-woocommerce-archive .loop article>div .media-content {
  height: 115px;
}

.woocommerce form .password-input, .woocommerce-page form .password-input {
  width: 100%;
}

@include media-breakpoint-down(md) {
  header {
    position: sticky;
    top: 0;
    z-index: 999999999999999;
    background: white;
  }
}

.mkoz-without-submit {
  button {
    display: none;
  }
}

.my-account {
  display: none;
}

.woocommerce-js {
  .my-account {
    display: inline;
  }
}

.woocommerce form .password-input, .woocommerce-page form .password-input {
  width: 100%;
}

.cvz-woocommerce-single .single-product-details .entry-row .entry-summary .entry-price-footer .quotation-call {
  background-color: #333!important;
}

@include media-breakpoint-down(sm) {

  .mkoz-product-sales {
    max-width: 540px;
  }
}

@media (max-width: 1060px) and (min-width: 768px) {
  .cvz-slider-text {
    display: none!important;
  }

.mkoz-responsive-block {

    .fl-col {
      width: 100% !important;

      .container {
        max-width: 100%;
      }
    }
  }
}

.mkoz-product-sales {
  @media (max-width: 1444px) and (min-width: 768px) {
    .fl-col {
      width: 100% !important;
    }

    .fl-testimonials-wrap {
      display: none;
    }
  }
  @include media-breakpoint-down(sm) {
    padding: 0 20px;
  }
}

.mkoz-home-products {

  .onsale {
    background-image: url(http://bedrock.lndo.site/app/themes/mkoz-2019//assets/src/img/tag.png);
    background-size: 92%;
    background-repeat: no-repeat;
    left: 0!important;
    right: initial!important;
    border-radius: 0px!important;
    color: transparent!important;
    background-color: transparent!important;
  }

  h2 {
    margin-top: 120px!important;
  }

  .price {
    color: #000!important;
    font-weight: 700;
  }

  img {
    max-width: 144px;
    float: right;
  }

  .button {
    width: 100%;
    font-weight: 500!important;
    padding: 12px 15px;
    background: transparent!important;
    border: 1px solid #ccc!important;
    &:before {
      font-family: FontAwesome !important;
      content: "\f0a9";
      padding-right: 10px;
    }

    &:hover {
      background: $corp-color!important;
      color: white!important;
    }
  }

}

.fl-module-content {
  margin: 20px 0;
}

.cvz-home-slider .fl-col-group {
  @include media-breakpoint-up(sm) {
    padding-left: 20px;
  }
}

.hide-block {
  display: none!important;
}

.show-block {
  display: block!important;
}

.cvz-content-slider {
  //background-image: url(../../../uploads/2018/08/hero-duurzaamheid.jpg);
  background-repeat: no-repeat;
  background-position: left center;
  background-attachment: scroll;
  background-size: cover;
  padding-top: 501px;
}

.cvz-content-box {
  margin-top: -102px;
  margin-bottom: 20px;
  .cvz-top {
    display: flex;
    align-items: center;
    background: #ffffff;

    i {
      margin: 0 20px;
    }

    i, i:before {
      width: 60px;
      height: 70px;
      font-size: 60px;
    }

    h1 {
      color: #f26a4c;
      padding: 20px 0;
    }
  }

  .cvz-bottom {
    background: #4c9da9;
    color: #ffffff;
    list-style: none;
    padding: 20px;
    padding-bottom: 40px;

    li {
      padding: 10px;
      padding-left: 90px;
      font-weight: 500;
      text-shadow: 0 2px 1px rgba(0,0,0,.22);
    }
  }
}

.page-tease .media-link a {
  margin-top: 20px;
}

.cvz-index-archive {
  padding: 30px 0;
}

.row-fold {
  position: relative;
  &:after {
    content: '';
    width: 0;
    height: 0;
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    border-top: 20px solid #fbf9f9;
    margin: 0 auto;
    left: 0;
    right: 0;
    position: absolute;
    z-index: 99;
    top: 0;
    display: flex;
    align-self: center;
  }
}

.cv-static-checkmark {
  display: flex;
  .static-circle {
    border: 1px solid #f7882f;
    position: relative;
    display: inline-block;
    vertical-align: top;
    border-radius: 50%;
    width: 2em;
    height: 2em;
    margin-right: 10px;
    padding: 17px;
    margin-top: 10px;


    .static-checkmark {
      opacity: 1;
      height: 1em;
      width: 0.5em;
      -webkit-transform-origin: left top;
      transform-origin: left top;
      border-right: 3px solid #f7882f;
      border-top: 3px solid #f7882f;
      content: '';
      left: 0.5em;
      top: 1em;
      position: absolute;
      transform: scaleX(-1) rotate(135deg);
    }
  }

  .static-text {
    font-size: 13px;
    padding-top: 20px;
  }
}

.cvz-round-borders {
  border-radius: $border-radius-default;
}

.cvz-round-borders-shadow {
  border-radius: $border-radius-default;
  overflow: hidden;
  -webkit-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
}

.home-row-questions {
  position: relative;

  &::after {
    content: '?';

    position: absolute;

    width: 60px;
    height: 60px;

    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;

    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    background-color: #ffffff;
    color: $corp-color;

    font-size: 2rem;
    font-weight: bold;

    @include media-breakpoint-down(sm) {
      display: none;
    }
  }

  .fl-col:first-child {
    .fl-col-content {
      @include cvz_border_radius(false, true, true, false);
    }
  }

  .fl-col:last-child {
    .fl-col-content {
      @include cvz_border_radius(true, false, false, true);
    }
  }
}

.show {
  display: block;
}

.modal-login {
  .form-line {
    position: relative;

    input {
      padding: 13.5px 15px;
      font-size: .875rem;
      width: 100%;
      border: 1px solid $color-grey;
      background-color: #f4f4f4;
      color: $body-color;

      @include cvz_border_radius();

      &::placeholder {
        color: rgba($body-color, 0.7);
      }
    }

    i {
      position: absolute;

      top: 50%;
      right: 10px;
      transform: translateY(-50%);

      font-size: 16px;
      line-height: 1;
      color: rgba($body-color, 0.7);
    }

    margin-bottom: 1rem;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .password-forgotten {
    margin-left: auto;
    margin-right: 10px;
    font-size: .875rem;
    color: rgba($body-color, 0.7);

    @include cvz_transition_link;

    &:hover {
      color: $corp-color;
    }
  }
}

.fl-node-61e1ca0c3a98b > .fl-col-content {
  background-image: url(../../../../../uploads/2020/05/Moeder-kind-op-bank-1.jpg) !important;
}

