.fl-row-content-wrap {
  padding: 50px 20px;
}

.fl-rich-text {
  //line-height: 2.5rem;

  @include cvz-highlight();
}

.scrollup {
  width: 42px;
  height: 42px;

  position: fixed;
  bottom: 3%;
  right: 2%;

  display: none;
  z-index: 999;

  background-color: $corp-color;
  border-radius: $border-radius-default;

  &:hover {
    background-color: rgba(247, 136, 47, 0.8);
  }

  @include media-breakpoint-down(sm) {
    top: 20px;
  }

  &:after {
    content: '\f102';
    font-family: FontAwesome;
    color: #ffffff;
    display: block;
    text-align: center;
    line-height: 40px;
  }

}

.onderhoud .cvz-fm-container {
  display: none!important;
}

.cvz-fm-container {
  position: fixed;

  top: 50%;
  transform: translateY(-50%);
  right: -550px;

  z-index: 999;

  @include media-breakpoint-down(sm) {
    top: initial;
    bottom: 0px;
    transform: initial;
    width: 100%;
  }

  @include media-breakpoint-down(md) {
    //border-radius: 5px 0 0 5px;
    -webkit-box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 1);
    box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 1);
    border-radius: 6px 0 0 6px;
  }

  .cvz-fm-inner {
    @include media-breakpoint-down(sm) {
      display: flex;
      width: 100%;
    }

    li {
      list-style: none;
      background: $corp-tertiary-color;

      border-bottom: 1px solid rgba(255, 255, 255, 0.31);
      width: 100%;

      @include media-breakpoint-down(sm) {
        background: #6e6e6e;
        border-radius: 0!important;
        position: relative;
      }

      &:first-child {
        border-radius: 6px 0 0 0;
      }

      &:last-child {
        border-bottom: none;
        border-radius: 0 0 0 6px;
      }

      a {
        font-size: 1.5rem;
        padding: 14px 15px;
        line-height: 100%;
        color: #ffffff;
        background: #ee3a43;
        display: block;

        @include media-breakpoint-down(sm) {
          position: relative;
          z-index: 1;
          background: #6e6e6e;
          text-align: center;
        }

        &:hover {
          color: #ffffff;
          cursor: pointer;
        }
      }

      .tooltiptext {
        visibility: hidden;
        width: 250px;
        background-color: #515050;
        color: #fff;
        text-align: center;
        border-radius: 6px;
        padding: 5px;
        position: absolute;
        z-index: -1;
        left: 60px;
        font-size: 1rem;
        margin-top: -44px;
        transition: all .3s ease-in-out;

        @include media-breakpoint-down(sm) {
          width: 132px;
          height: 20px;
        }

        &::after {
          content: "";
          position: absolute;
          top: 34%;
          right: -10px;
          margin-left: -5px;
          border-width: 5px;
          border-style: solid;
          border-color: #515050 transparent transparent transparent;
          transform: rotate(269deg);
        }
      }

      &:hover {
        opacity: 0.9;
        .tooltiptext {
          visibility: visible;
          left: -262px;
          z-index: -1;

          @include media-breakpoint-down(sm) {
            height: 390px;
            bottom: -240px;
            left: initial;
            margin-left: 1px;
            width: 100%;
          }
        }
      }

    }
  }
}

.fl-icon-wrap {
  display: block;

  margin-top: 10px;
  text-align: center;

  .fl-icon {
    display: block;

    font-size: 2.5rem;

    .fa,
    [class^="icon-"] {
      float: none;
      font-size: inherit !important;

      &::before {
        font-size: inherit !important;
      }
    }
  }


  .fl-icon-text {
    height: auto !important;
    display: block;
    text-align: center;
    padding-left: 0;

    h3 {
      font-size: 1.375rem;
      font-weight: 500;
    }

    p {
      font-size: 0.875rem;
      line-height: 1.25rem;
    }
  }
}

.fl-accordion-item {
  border: 1px solid $color-grey;

  @include cvz_border_radius();

  margin-bottom: 1.31rem;
  background-color: #f4f4f4;

  .fl-accordion-button {

  }

  .fl-accordion-button-label {
    color: rgba($body-color, 0.8);

    @include cvz_transition_link();
  }

  .fl-accordion-button-toggle {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    width: 50px;
    background-color: $color-grey;
    @include cvz_transition_bg();

    .fa {
      color: rgba($body-color, 0.8);
    }
  }

  &.fl-accordion-item-active {
    .fl-accordion-button {
      position: relative;

      &::after,
      &::before {
        opacity: 1;
      }
    }

    .fl-accordion-button-label {
      color: $body-color;
    }

    .fl-accordion-button-toggle {
      background-color: $corp-color;

      @include cvz_border_radius(false, false, false);

      .fa {
        color: #ffffff;
      }
    }
  }

  .fl-accordion-content {
    font-size: 0.875rem;
    line-height: 1.375rem;

    p {
      margin-bottom: 5px;
    }
  }
}

.cvz-post-grid {
  .cvz-post-grid-post {
    position: relative;

    @include cvz_border_radius();

    .cvz-post-grid-date {
      position: absolute;
      display: flex;
      flex-direction: column;

      z-index: 10;

      background-color: #ffffff;
      align-items: center;

      top: 18px;
      right: 18px;

      width: 42px;
      height: 50px;

      @include cvz_border_radius(false, true, false, false, 3px);

      span {
        &.day {
          display: flex;
          font-size: 1.25rem;
          font-weight: 600;

          height: 34px;
          align-items: center;
        }

        &.month-year {
          display: flex;
          background-color: $corp-color;
          font-size: 0.55rem;
          font-weight: 600;
          color: #ffffff;
          text-transform: uppercase;

          width: 100%;
          height: 16px;
          justify-content: center;
          align-items: center;
        }
      }
    }

    .cvz-post-grid-meta {
      display: flex;

      font-size: 0.6875rem;
      font-weight: 500;
      color: rgba($body-color, .5);

      margin: 0 .2rem 1rem .2rem;

      i[class^="icon-"] {
        margin-right: .4rem;
      }

      a {
        color: inherit;

        @include cvz_transition_link();

        &:hover {
          color: $color-dark-grey;
        }
      }

      .cvz-post-grid-category {

      }

      .cvz-post-grid-author {
        margin-left: auto;
      }
    }

    .cvz-post-grid-image {
      padding: 15px 15px 0 15px;

      img {
        @include cvz_border_radius();
      }
    }

    .cvz-post-grid-title {
      font-size: 1.125rem;
      color: $body-color;
      margin-bottom: 0.625rem;

      a {
        color: inherit;

        @include cvz_transition_link();

        &:hover {
          color: $corp-color;
        }
      }
    }

    .cvz-post-grid-content {
      font-size: .875rem;
      line-height: 1.625rem;
    }

    .cvz-post-grid-more {
      display: block;
      font-size: 0.8125rem;
      font-weight: 600;
      color: $body-color;
      text-align: right;

      margin-top: 1.5rem;

      text-transform: uppercase;

      @include cvz_transition_link();

      &:hover {
        color: $corp-color;
      }

      i {
        font-size: 1rem;
        margin-left: .5rem;
        color: $corp-color;
      }
    }
  }
}

.fl-module-cvz-testimonials {
  .fl-testimonials-wrap {

    margin-left: 20px;

    .bx-wrapper {
      border: 1px solid rgba(128, 128, 128, 0.2);
      height: 328px !important;
      margin-top: 30px;
    }

    &.fl-testimonials-align-center {
    }

    &.fl-testimonials-align-right {
      text-align: right;
    }

    .fl-testimonial {
      font-size: 1.125rem;


      .fl-testimonial-text {
        padding: 30px 30px 10px 30px;
      }

      .fl-testimonial-footer {
        padding: 0px 30px;
        display: flex;

        .fl-testimonial-footer-right {

          margin-left: auto;
          order: 2;
          .fl-testimonial-rating {
            margin-top: 10px;
            padding: 12px;
            background: #f17e00;
            border-radius: 30px;
            color: white;
          }
        }
      }

      p {
        font-size: 1.3rem;
        font-style: italic;
        height: 130px;
        overflow: hidden;

      }

      .fl-testimonial-author {
        font-weight: 600;

        margin-top: 0.5rem;
      }


    }

    .bx-pager.bx-default-pager a {
      @include cvz_transition_link();
      @include cvz_border_radius(false, true, false, true, 2.5px);

      background-color: $color-grey;
      opacity: 1;

      &:hover,
      &.active {
        background-color: $corp-color;
      }
    }
  }

  .fl-testimonial-controls {
    background: #f2f2f2;
    width: 100%;
    height: 52px;
    position: absolute;
    bottom: 0;

    .fl-slider-prev, .fl-slider-next {
      top: inherit!important;
      bottom: 14px!important;
    }

    .fl-slider-prev {
      right: 38px !important;
    }

    .fl-slider-next {
      right: 18px !important;
    }
  }

}

.cvz-cta-container {
  background: #ffffff;
  padding-top: 5px;

  @include cvz_border_radius(false, false, true, true);

  .cvz-cta-container-inner {
    padding: 2.25rem 0;

    .cvz-cta-col {
      position: relative;
      margin-bottom: 1rem;
      padding-top: 1rem;

      border-top: 1px solid $color-grey;

      &:first-of-type {
        border-top: none;
        padding-top: 0;
      }

      &:last-of-type {
        margin-bottom: 0;
      }

      @include media-breakpoint-down(md) {
        border-top: 5px solid $corp-color;
        padding-top: 30px;
        padding-bottom: 30px;
      }

      @include media-breakpoint-up(md) {
        margin-bottom: 0;
        padding-top: 0;
        border-top: none;
      }

      position: relative;

      padding-right: 15px;
      padding-left: 15px;

      h2 {
        font-size: 1rem;

        margin-bottom: 0.75rem;
      }

      p {
        font-size: 0.875rem;
        line-height: 1.5rem;

        color: rgba(0, 0, 0, 1);

        margin-bottom: 1.75rem;

      }

      a {
        h2 {
          color: #515050;
        }

        &:hover {

          h2 {
            color: $corp-color;
          }
        }

        >span {
          position: absolute;
          bottom: -10px;

          font-size: 0.875rem;
          color: $corp-color;
          font-weight: 600;
          text-transform: uppercase;


          i {
            padding-right: 0.4rem;
            color: $corp-color;
          }
        }
      }


      form {
        display: flex;

        justify-content: center;

        input[type="text"] {
          border-radius: 0 0 0 5px;
          outline: 0;
          background-color: transparent;
          color: $body-color;
          padding: .3rem .5rem;
          width: 250px !important;
          border-right: 0;
        }



        input,
        button {
          margin: 0;
          padding: 0;
          border: 1px solid $color-grey;
          font-size: .875rem;
        }

        input[type="submit"],
        button[type="submit"] {
          border-radius: 0 5px 0 0;

          @include cvz_transition_bg();

          outline: 0;
          background-color: $corp-color;
          border-color: $corp-color;
          color: #ffffff;
          font-weight: 500;
          text-transform: uppercase;
          padding: .3rem .5rem;
          line-height: 1;
          cursor: pointer;

          &:hover {
            background-color: rgba($corp-color, 0.9);
          }
        }
      }

      .cvz-search-block {
        @include media-breakpoint-down(md) {
          input[type="text"] {
            width: 220px !important
          }
        }
      }

      .cvz-cta-col-inner {
        @include media-breakpoint-up(md) {
          &::after {
            position: absolute;
            content: ' ';
            border-right: 1px solid $color-grey;

            width: 1px;
            height: 100%;

            top: 0;
            right: 0;
          }
        }
      }

      &:last-child {
        .cvz-cta-col-inner {
          &::after {
            border-color: transparent;
          }
        }
      }
    }
  }
}

.cvz-slider-text {
  position: relative;
  @include media-breakpoint-down(md) {
    display: none;
  }
  .cvz-slider-text-container {
    position: absolute;
    z-index: 1;
    background: #ffffff;
    padding-top: 5px;

    &::before {
      top: 0px;
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      margin-right: -15px;
      margin-left: -15px;
      box-shadow: 0 2px 4px 1px rgba(0, 0, 0, 0.11);

    }

    &::after {
      content: '';
      position: absolute;
      top: 11px;
      z-index: 0;
      left: 12px;
      width: 102%;
      height: 100%;
      margin-right: -15px;
      margin-left: -15px;
      background: -moz-linear-gradient(top, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%); /* FF3.6-15 */
      background: -webkit-linear-gradient(top, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%); /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(to bottom, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=0 );
    }

    @include cvz_border_radius(false, false, true, true);

    .cvz-slider-text-container-inner {
      padding: 1.25rem 5px;
      position: relative;
      z-index: 1;

      .cvz-slider-text-col {
        position: relative;
        margin-bottom: 1rem;
        padding-top: 1rem;

        border-top: 1px solid $color-grey;

        &:first-of-type {
          border-top: none;
          padding-top: 0;
        }

        &:last-of-type {
          margin-bottom: 0;
        }

        @include media-breakpoint-down(md) {
          border-top: 5px solid $corp-color;
          padding-top: 30px;
          padding-bottom: 30px;
        }

        @include media-breakpoint-up(md) {
          margin-bottom: 0;
          padding-top: 0;
          border-top: none;
        }

        position: relative;

        padding-right: 15px;
        padding-left: 15px;

        ul {
          padding-left: 25px;
          list-style: none;
          margin-left: 0;
          padding-left: 0;
          li {
            padding-left: 1em;
            text-indent: -1.1em;
            font-size: 1rem;
            &:before {
              content: '✓';
              color: $corp-color;
              font-weight: 700;
              padding-right: 5px;
            }
          }
        }

        h4 {
          font-weight: 700;
        }

        h2 {
          color: $corp-color;
          font-weight: 700;

          margin-bottom: 0.75rem;
        }

        p {
          font-size: 0.875rem;
          line-height: 1.5rem;

          color: rgba(0, 0, 0, 1);

          margin-bottom: 1.75rem;

        }

        a {
          display: block;
          color: $corp-color;
          font-weight: 400;
          padding-left: 23px;
          text-indent: -11px;
          font-size: 1rem;

          i {
            padding-right: 0.4rem;
            color: $corp-color;
          }
        }


        form {
          display: flex;

          justify-content: center;

          input[type="text"] {
            border-radius: 0 0 0 5px;
            outline: 0;
            background-color: transparent;
            color: $body-color;
            padding: .3rem .5rem;
            width: 250px !important;
            border-right: 0;
          }


          input,
          button {
            margin: 0;
            padding: 0;
            border: 1px solid $color-grey;
            font-size: .875rem;
          }

          input[type="submit"],
          button[type="submit"] {
            border-radius: 0 5px 0 0;

            @include cvz_transition_bg();

            outline: 0;
            background-color: $corp-color;
            border-color: $corp-color;
            color: #ffffff;
            font-weight: 500;
            text-transform: uppercase;
            padding: .3rem .5rem;
            line-height: 1;
            cursor: pointer;

            &:hover {
              background-color: rgba($corp-color, 0.9);
            }
          }
        }

        .cvz-search-block {
          @include media-breakpoint-down(md) {
            input[type="text"] {
              width: 220px !important
            }
          }
        }

        .text-block-review {
          border-top: #cccccca6 1px solid;
          padding-top: 30px;
          margin-top: 30px;

          position: absolute;
        }

        .cvz-slider-text-col-inner {
          @include media-breakpoint-up(md) {
            &::after {
              position: absolute;
              content: ' ';
              border-right: 1px solid $color-grey;

              width: 1px;
              height: 100%;

              top: 0;
              right: 0;
            }
          }
        }

        &:last-child {
          .cvz-slider-text-col-inner {
            &::after {
              border-color: transparent;
            }
          }
        }
      }
    }
  }
}

.cvz-mcw-container {
  box-shadow: 0 2px 4px 1px rgba(0, 0, 0, 0.18);
  background: #ffffff;
  padding-top: 5px;

  .cvz-mcw-container-inner {
  }

  @include cvz_border_radius(false, false, true, true);

  .cvz-mcw-col {
    float: left;
  }
}

.fl-module-cvz-numbered-list {
  margin-top: 1.75rem;

  @include media-breakpoint-up(lg) {
    margin-top: 0;
  }

  ol {
    margin: 0;
    padding: 0;

    list-style: none;

    li {
      margin-bottom: 1.75rem;

      span {
        position: relative;
        display: flex;

        align-items: center;
        font-weight: 500;

        &::before {
          display: flex;

          margin-right: 1rem;

          width: 36px;
          height: 36px;

          font-size: 1rem;
          font-weight: 600;

          justify-content: center;
          align-items: center;

          content: attr(data-index);

          @include cvz-border_radius;
          @include cvz_transition_link;

          background: $color-grey;
          color: $body-color;
        }
      }

      a {
        color: $body-color;

        &:hover {
          span {
            &::before {
              background: $corp-color;
              color: #ffffff;
            }
          }
        }
      }
    }
  }

  & .wit {
    ol {
      li {
        span {
          color: #FFFFFF;
          &::before {
            background: $corp-color;
            color: #ffffff;
          }
        }
      }
    }

    a {
      color: #FFFFFF;

      &:hover {
        span {
          &::before {
            background: #FFFFFF;
            color: $corp-color;
          }
        }
      }
    }
  }
}

.fl-cvz-faq-list {
  margin-top: 1.75rem;
  border-radius: $border-radius-default;
  overflow: hidden;

  -webkit-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);

  @include media-breakpoint-up(lg) {
    margin-top: 0;
  }

  .fl-cvz-faq-list-header {
    background: #ffffff;
    padding: 20px;

    .fl-cvz-faq-list-icon {
      display: inline-block;
      padding-right: 20px;

      i {
        width: auto;
        height: auto;
        display: inline-block;
        vertical-align: bottom;

        &:before {
          font-size: 60px;
          color: #4c9da9;

          width: auto;
          height: auto;
        }
      }
    }

    .fl-cvz-faq-list-title {
      display: inline-block;
      vertical-align: top;

    }
  }

  .fl-cvz-faq-list-wrapper {
    background-color: #4c9da9;
    padding: 30px;
    .fl-cvz-faq-list-items {
      padding-left: 80px;
      li {
        list-style: none;
        span {
          position: relative;
          display: flex;

          align-items: center;
          font-weight: 500;
          color: #ffffff;
          text-shadow: 0px 2px 1px rgba(0, 0, 0, 0.22);

          padding-bottom: 20px;

        }
      }
    }
  }
}

.fl-cvz-info-box {
  border-radius: $border-radius-default;
  overflow: hidden;

  -webkit-box-shadow: 0 2px 4px 0 rgba(0,0,0,.5);
  box-shadow: 0 2px 4px 0 rgba(0,0,0,.5);

  .fl-cvz-info-box-header {
    background: #6c6f70;

    .fl-cvz-info-box-title {
      padding: 16px 10px 7px 10px;

      h3 {
        color: #ffffff;
        margin-bottom: 0;
        padding: 0px 10px;
      }
    }
  }

  .fl-cvz-info-box-wrapper {
    background: #fbf9f9;
    padding: 10px;

    .fl-cvz-info-box-content {
      h5 {
        line-height: 2.5rem;
      }

      hr {
        margin-top: 0rem;
        margin-bottom: 0rem;
        border: 0;
        border-top: 2px solid #cccccc;
      }
    }

    .fl-cvz-info-box-items {
      padding: 20px 0px;
      li {
        list-style: none;
        padding-bottom: 20px;
        i {
          display: inline-block;
          vertical-align: middle;
          width: auto;
          height: auto;

          &:before {
            display: inline-block;
            font-size: 2.5rem;
            text-align: center;
            padding-right: 20px;
            color: #6c6f70;

            width: 80px;
            height: auto;
          }
        }

        span {
          display: inline-block;
          vertical-align: middle;
        }
      }
    }

    .fl-button-wrap {
      padding-bottom: 20px;
    }

    .fl-module-button .fl-button-left,
    .fl-module-button .fl-button-right {
      text-align: center;
    }
  }
}
