@import "../base";
@import "../_includes/mixins";

.cvz-my-cvzeker-menu {
  background-color: #f4f4f4;

  border-top: 5px solid $corp-secondary-color;
  box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.3);

  .nav {
    position: relative;
    display: flex;
    list-style: none;
    width: 100%;
    margin: 0;
    padding: 0;

    font-family: $corp-font-family;
    font-weight: 500;
    font-size: 1rem;

    @include media-breakpoint-down(md) {
      display: block;
    }

    > li {
      display: flex;
      align-items: center;
      padding: 14px 20px;

      @include media-breakpoint-up(md) {
        &:first-child {
          padding-left: 0px;
        }

        &:last-child {
          padding-right: 0px;
        }
      }

      @include media-breakpoint-down(md) {
        justify-content: center;
      }

      &.current {
        font-weight: 600;
      }

      a {
        color: $color-dark-grey;

        &:hover {
          opacity: 0.9;
        }

      }
    }
  }
}

.cvz-my-cvzeker {
  padding: 80px 0;

  h2 {
    margin-bottom: 1.5rem;
    font-size: 1.6rem;
  }

  >div {
    margin-bottom: 1.5rem;
    line-height: 2;
  }

  @keyframes blink {
    0% {
      opacity: .2;
    }
    20% {
      opacity: 1;
    }
    100% {
      opacity: .2;
    }
  }

  .cvz-my-cvzeker-message span {
    animation-name: blink;
    animation-duration: 1.4s;
    animation-iteration-count: infinite;
    animation-fill-mode: both;
  }

  .cvz-my-cvzeker-message span:nth-child(2) {
    animation-delay: .2s;
  }

  .cvz-my-cvzeker-message span:nth-child(3) {
    animation-delay: .4s;
  }
}

.cvz-edit-relation {
  form {
    padding: 0px!important;
  }
}

.fl-accordion-button {
  cursor: pointer;
  display: flex;

  .cvz-label-new {
    &:after {
      color: #090;
      content: 'Nieuw';
      padding: 0 4px;
    }
  }

  .cvz-label-sent {
    &:after {
      color: #090;
      content: 'Verzonden';
      padding: 0 4px;
    }
  }

  span.referentie{
    padding: 0 4px;
  }

  @include media-breakpoint-up(sm) {
    .cvz-label-new {
      &:after {
        position: absolute;
        right: 15px;
      }
    }

    .cvz-label-sent {
      &:after {
        position: absolute;
        right: 15px;
      }
    }
  }

  @include media-breakpoint-down(md) {
    span.referentie{
      display: none;
    }
  }
}

.cvz-get-boilers, .cvz-get-new-orders, .cvz-get-new-maintenance {
  >div {
    display: none;
    &:nth-child(2) {
      display: block;
    }

    &:nth-child(3) {
      display: block;
    }

    &:nth-child(4) {
      display: block;
    }

    &:first-child{
      display: block;
    }

    &.cvz-my-cvzeker-message {
      display: block;
    }
  }
}

.fl-accordion-button:focus {
  outline: 0
}

.fl-accordion-button-label {
  width: 100%;
  padding: 0 15px;
  position: relative;

  @include media-breakpoint-up(md) {
    height: 39px;
  }

  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 14px;
}

.fl-accordion-button-icon {
  line-height: inherit;
  vertical-align: middle;
}

.fl-accordion-content {
  display: none;

  padding: 15px;
}