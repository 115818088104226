$font-family-base: 'Avenir Next LT Pro';
$font-size-base: 1rem;

$body-color: #515050;

$link-color: $corp-color;

$link-hover-color: rgba($corp-color, .8);
$link-hover-decoration: none;


// checkmark animation
$loader-size: 2em;
$check-height: $loader-size/2;
$check-width: $check-height/2;
$check-left: ($loader-size/6 + $loader-size/12);
$check-thickness: 3px;

// checkmark animation
$loader-size-static: 10em;
$check-height-static: $loader-size-static/2;
$check-width-static: $check-height-static/2;
$check-left-static: ($loader-size-static/6 + $loader-size-static/12);
$check-thickness-static: 3px;

//$input-btn-padding-x: 1.75rem;
//$input-btn-padding-y: .75rem;
//$input-btn-padding-x-sm: 2rem;
//$input-btn-padding-y-sm: .375rem;
//$input-btn-focus-box-shadow: none;
//
$headings-font-weight: 600;
//$headings-line-height: 1.252;
//$headings-margin-bottom: 2rem;
//
//$font-size-sm: .875rem;
//
//$h1-font-size: $font-size-base * 2 !default;
