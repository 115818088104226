@import "../base";


.footer-top {
  margin-top: 2rem;
  margin-bottom: 2rem;
  h2 {
    font-family: "Titillium Web", sans-serif;
    font-weight: 700;
    font-size: 28px;
    color: #0a0000;
    text-align: center;
  }

  img {
    max-width: 100%;
  }
}

.single-product, .woocommerce-cart{
  .footer-top {
    margin-top: 5rem;
    display: none;
    p {
      display: none;
    }
  }

  .footer-widgets {
    margin-top: 8rem;
  }
}

.footer-widgets {
  background: #333;
  color: rgba(#ffffff, 0.6);

  a {
    color: rgba(#ffffff, 0.6);

    @include cvz_transition_link();

    &:hover {
      color: rgba(#ffffff, 0.8);
    }
  }

  .footer-widgets-inner {
    padding: 1.375rem 0;
    font-size: 0.875rem;
    line-height: 1.62rem;

    @include media-breakpoint-up(lg) {
    }

    @include media-breakpoint-down(xs) {
      padding: 4.375rem 15px;
    }

    .col {
      @include media-breakpoint-down(xs) {
        padding-bottom: 20px;
      }
      .widget_nav_menu {
        &:nth-child(2) {
          margin-top: 10px;
        }
      }
    }

    .widget-title {
      font-size: 1.125rem;
      font-weight: 500;
      text-transform: uppercase;

      color: #ffffff;
      line-height: 2.425rem;

      margin-bottom: 1rem;
    }

    ul {
      list-style: none;
      margin: 0;
      padding: 0;
    }

    .contact-option-list {
      li {
        position: relative;
        margin-bottom: 1.62rem;

        padding-left: 52px;

        .icon-box {
          position: absolute;
          display: flex;
          align-items: center;
          justify-content: center;

          top: 2.5px;
          left: 0;

          width: 42px;
          height: 42px;
          text-align: center;

          background: #ffffff;
          color: #5d5d5d;

          font-size: 1.25rem;

          border-radius: 50%;
        }
      }
    }

    .menu {
      a {
        position: relative;

        display: flex;
        align-items: center;
        padding-left: 1rem;

        &::before {
          content: '\f105';
          position: absolute;

          left: 0;

          font: normal normal normal 14px/1 FontAwesome;
          font-size: inherit;
          text-rendering: auto;
          -webkit-font-smoothing: antialiased;
          height: 14px;
          margin-top: -1px;
        }
      }
    }
  }
}