@import "../base";
@import "../_includes/mixins";

.cvz-btn, .woocommerce-Button {
  display: inline-block;
  color: #ffffff;

  border: none;
  cursor: pointer;

  @include cvz_transition_bg();

  @include cvz_border_radius();

  font-family: $corp-font-family;
  font-weight: 600;

  text-transform: uppercase;
  letter-spacing: 0.8px;

  line-height: 32px;
  height: 32px;
  font-size: 0.7rem;
  padding: 0 .875rem 0 1rem;

  @include media-breakpoint-up(xl) {
    &:not(.cvz-btn-sm) {
      line-height: 42px;
      height: 42px;
      font-size: 0.875rem;
      padding: 0 1rem 0 1.125rem;
    }

    &.cvz-btn-sm {
      font-size: 0.8rem;
    }
  }

  &:hover {
    color: #ffffff;
  }

  @each $item in $colors {
    $colorName: nth($item, 1);
    $colorValue: nth($item, 2);

    &.cvz-btn-#{$colorName} {
      background-color: $colorValue;

      &:hover {
        background-color: rgba( $colorValue, 0.8 );
      }
    }

    &.cvz-btn-#{$colorName}-outline {
      border: 1px solid $colorValue;
      background: #ffffff;
      color: $colorValue;

      &:hover {
        background-color: $colorValue;
        color: #ffffff;
      }
    }

    i {
      font-size: .875rem;

      margin-left: 0.75rem;
      margin-right: -0.2rem;

      @include media-breakpoint-up(xl) {
        font-size: 1rem;
      }
    }
  }
}