.footer-bottom {
  background: #333;
  color: #ffffff;

  .footer-bottom-inner {
    padding: 0 0;

    .footer-bottom-newsletter {
      label {
        font-family: $corp-font-family;
        font-size: 1rem;
        font-weight: 500;
        text-transform: uppercase;
        margin-bottom: .3rem;
      }

      form {
        display: flex;

        input {
          margin: 0;
          padding: 0;

          border: 1px solid #ffffff;
          font-size: .875rem;
        }

        input[type="email"] {
          @include cvz_border_radius(false, false, false, true);

          outline: 0;
          background-color: transparent;

          color: #ffffff;

          padding: .3rem .5rem;

          width: 250px !important;

          border-right: 0;

          &::placeholder {
            color: rgba(#ffffff, 0.5);
          }
        }

        input[type="submit"] {
          @include cvz_border_radius(false, true, false, false);
          @include cvz_transition_bg($corp-color, #ffffff);

          background-color: #ffffff;
          color: $corp-secondary-color;

          font-family: $corp-font-family;

          font-weight: 500;
          text-transform: uppercase;

          padding: .3rem .5rem;
          line-height: 1;

          cursor: pointer;
        }
      }
    }

    .footer-bottom-logo {
      text-align: right;

      img {
        max-height: 67px;
      }
    }
  }
}