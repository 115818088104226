@font-face {
  font-family: 'Avenir Next LT Pro';
  src: local('Avenir Next LT Pro Demi'), local('AvenirNextLTPro-Demi'),
  url('./../../fonts/avenir_next/AvenirNextLTPro-Demi.woff2') format('woff2'),
  url('./../../fonts/avenir_next/AvenirNextLTPro-Demi.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Avenir Next LT Pro';
  src: local('Avenir Next LT Pro Regular'), local('AvenirNextLTPro-Regular'),
  url('./../../fonts/avenir_next/AvenirNextLTPro-Regular.woff2') format('woff2'),
  url('./../../fonts/avenir_next/AvenirNextLTPro-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Avenir Next LT Pro';
  src: local('Avenir Next LT Pro Medium'), local('AvenirNextLTPro-Medium'),
  url('./../../fonts/avenir_next/AvenirNextLTPro-Medium.woff2') format('woff2'),
  url('./../../fonts/avenir_next/AvenirNextLTPro-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Avenir Next LT Pro';
  src: local('Avenir Next LT Pro Bold'), local('AvenirNextLTPro-Bold'),
  url('./../../fonts/avenir_next/AvenirNextLTPro-Bold.woff2') format('woff2'),
  url('./../../fonts/avenir_next/AvenirNextLTPro-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Avenir Next LT Pro';
  src: local('Avenir Next LT Pro Italic'), local('AvenirNextLTPro-It'),
  url('./../../fonts/avenir_next/AvenirNextLTPro-It.woff2') format('woff2'),
  url('./../../fonts/avenir_next/AvenirNextLTPro-It.woff') format('woff');
  font-weight: normal;
  font-style: italic;
}
