@mixin cvz_border_radius($top: false, $right: true, $bottom: false, $left: true, $width: 5px) {
  $borders: 0 0 0 0;

  @if $top == true {
    $borders: set-nth($borders, 1, $width)
  }

  @if $right == true {
    $borders: set-nth($borders, 2, $width)
  }

  @if $bottom == true {
    $borders: set-nth($borders, 3, $width)
  }

  @if $left == true {
    $borders: set-nth($borders, 4, $width)
  }

  border-radius: $borders;
}

@mixin cvz_transition_link() {
  transition: all .15s linear;
}

@mixin cvz_transition_bg($bgColor: '', $color: '', $borderColor: '') {
  transition: all .15s linear;

  @if $bgColor != '' and $color != '' {
    &:hover {
      background-color: $bgColor;
      color: $color;

      @if $borderColor != '' {
        border-color: $borderColor;
      }
    }
  }
}

@mixin cvz-highlight() {
  .cvz-highlight {
    display: inline-block;

    position: relative;
    z-index: 2;
    padding: 4px 5px;

    font-weight: 700;
    line-height: 1.3rem;
    box-shadow: 3px 3px 0 0 #cb6819;
    white-space: nowrap;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;

      z-index: -1;
      background: #f7882f;
    }
  }
}