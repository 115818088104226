@import "../base";
@import "../_includes/mixins";
@import "../_includes/config";
@import "../_includes/bootstrap";

@import "~flatpickr/dist/flatpickr.min.css";

.maintenanceplanner {

  .collapse-wrapper {
    padding: 20px 0;

    #planning-customer-information {
      padding: 0px;
    }

    #get_available_slots {
      padding: 0px;

      .mcw_form_adres {
        margin-top: 20px;
        .mcw_form_adres_house {
          display: flex;
          width: 100%;

          @include media-breakpoint-down(sm) {
            flex-direction: column;
          }
          .mcw_form_adres_house_row {
            width: 100%;
          }
        }
      }

      .get-available-dates {
        margin-top: 20px;
      }
    }

    .slots {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 20px;
    }

    .slot {
      display: inline-block;

      position: relative;
      margin-top: 20px;
      margin-right: 20px;


      label {
        cursor: pointer;
        position: relative;
        font-size: 1rem;
        padding: 40px 0px 30px 0px;
        width: 173px;
        text-align: center;
        border: 1px solid #e1e1e1;
        border-radius: $border-radius-default;
        font-weight: 500;
        color: #7d7c7c;
        float: left;

        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;

        img {
          max-width: 70px;
          position: absolute;
          margin-left: auto;
          margin-right: auto;
          left: 0;
          right: 0;
          bottom: 38px;

          -webkit-filter: grayscale(100%);
          filter: grayscale(100%);
        }


        &:hover {
          img {
            -webkit-filter: grayscale(0%);
            filter: grayscale(0%);
          }
        }
      }

      input[type="radio"], input[type="checkbox"] {
        visibility: hidden;
        display: none;
      }

      input[type="radio"]:checked + label, input[type="checkbox"]:checked + label {
        background: #f9f9f9;
        border: 1px solid $corp-color;
        -webkit-box-shadow: 0px 0px 0px 1px rgba(247, 137, 47, 1);
        -moz-box-shadow: 0px 0px 0px 1px rgba(247, 137, 47, 1);
        box-shadow: 0px 0px 0px 1px rgba(247, 137, 47, 1);

        img {
          -webkit-filter: grayscale(0%);
          filter: grayscale(0%);
        }

        &::after {
          font-family: simple-line-icons, sans-serif;
          font-size: 18px;
          content: '\e080';

          position: absolute;

          background: $corp-color;
          color: #fff;
          padding: 3px 11px;
          right: 0px;
          top: 0px;
          border-radius: 0px 0 0 6px;
        }
      }
    }
  }

  .flatpickr-calendar.inline {
    margin: 0 auto;
  }

  .planning-date.flatpickr-input.form-control.input {
    visibility: hidden;
  }

  .flatpickr-day.selected,
  .flatpickr-day.startRange,
  .flatpickr-day.endRange,
  .flatpickr-day.selected.inRange,
  .flatpickr-day.startRange.inRange,
  .flatpickr-day.endRange.inRange,
  .flatpickr-day.selected:focus,
  .flatpickr-day.startRange:focus,
  .flatpickr-day.endRange:focus,
  .flatpickr-day.selected:hover,
  .flatpickr-day.startRange:hover,
  .flatpickr-day.endRange:hover,
  .flatpickr-day.selected.prevMonthDay,
  .flatpickr-day.startRange.prevMonthDay,
  .flatpickr-day.endRange.prevMonthDay,
  .flatpickr-day.selected.nextMonthDay,
  .flatpickr-day.startRange.nextMonthDay,
  .flatpickr-day.endRange.nextMonthDay {
    background-color: $corp-color;
    border-color: $corp-color;
  }

  @media screen and (min-width: 767px) {
    $dayWidth: 52px;
    $width: $dayWidth * 7;

    .flatpickr-calendar {
      width: $width;
    }
    .dayContainer {
      width: $width;
      min-width: $width;
      max-width: $width;
    }
    .flatpickr-days {
      width: $width;
    }
    .flatpickr-day {
      max-width: $dayWidth;
      height: $dayWidth;
      line-height: $dayWidth;
      font-size: 15px;
    }
  }

  .planning-date {
    margin: 35px 0 0 0;
  }

  .planning-time-window {
    display: none;
    margin: 35px 0;

    text-align: center;

    .mkoz-button-list {
      justify-content: center;
    }
  }

  .btn-submit {
    display: none;

    cursor: pointer;
  }
}