@import "../base";
@import "../_includes/mixins";

#mcw_payment {
  .payment-period {
    .mcw_form_answer {
      margin: 10px 0;
      display: inline-block;

      position: relative;
      @include media-breakpoint-down(md) {
        width: 100%;
      }

      input {
        display: none;
      }
    }
  }
}

.password-row {
  .mcw_form_name_row {
    margin-top: -20px;
  }
}


@include media-breakpoint-up(md) {
  .shopping-cart {
    #mcw_payment {
      position: sticky;
      top: 0;
      height: 590px;
    }
  }

  .check-out {
    #mcw_payment {
      position: sticky;
      top: 0;
      height: 350px;
    }
  }
}

.cvz-mcw-container {
  p {
    font-size: 0.9rem;
  }

  .error_message {
    color: #FF0000;
    text-align: right;
  }

  div.loader {
    display: block;
    width: 100%;
    height: 280px;
    text-align: center;

    img {
      position: relative;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  label {
    font-size: 1rem;
  }

  span {
    font-size: 1rem;
  }

  .mcw-text-row {
    display: block;
    padding: 30px;
    padding-bottom: 0px;

    @include media-breakpoint-down(md) {
      padding: 30px 10px;
    }

    strong {
      font-weight: 600;
    }

    .mcw-text-row-strong {
      padding-right: 20px;
    }
  }

  .mcw_form_steps {

    .mcw_top h2 {
      padding: 20px 0;
      padding-bottom: 40px;
      text-align: center;
      border-bottom: 1px solid rgba(81, 80, 80, 0.13);
      font-size: 38px;

      @include media-breakpoint-down(xs) {
        font-size: 1.6rem;
        display: none;
      }
    }

    .mcw_top_menu_container {
      display: none;
    }

    .mcw-text-row {
      display: none;
    }

    #mcw_payment {
      display: none;

    }
    .mcw_form_buttons {
      width: 100%;

      #prevBtn {
        float: left;
      }

      #nextBtn {
        float: right;
      }
    }
  }
  .mcw_top {
    h2 {
      padding: 40px 0;
      text-align: center;

      span {
        font-size: 2.25rem;
      }

      @include media-breakpoint-down(xs) {
        font-size: 1.5rem;

        span {
          font-size: 1.5rem;
        }
      }
    }

    .mcw_top_menu_container {
      margin-left: -15px;
      margin-right: -15px;
      background: #f9f9f9;

      .mcw_top_menu {
        display: flex;
        width: 93%;
        margin-left: auto;
        margin-right: auto;

        @include media-breakpoint-down(sm) {
          display: block;
        }

        div {
          background: #f9f9f9;
          display: flex;
          flex-direction: column;
          flex-basis: auto;
          flex-wrap: nowrap;
          width: 100%;
          padding: 16px 0;
          text-align: center;
          color: #c5c5c5;
          text-transform: uppercase;

          span {

            a {
              color: #515050;
            }

            @include media-breakpoint-down(lg) {
              font-size: 0.7rem;
            }

            @include media-breakpoint-down(sm) {
              font-size: 1rem;
            }

            &::before {
              border: 2px solid #c5c5c5;
              display: inline-block;
              margin-right: 15px;
              font-family: simple-line-icons, sans-serif;
              font-size: 13px;
              content: '\E080';
              background: #f9f9f9;
              color: #f9f9f9;
              padding: 0px 3px;
              border-radius: $border-radius-default;
            }
            @include media-breakpoint-down(sm) {
              width: 95%;
            }
          }
        }

        .active {
          background: $corp-color;
          color: #ffffff;
          a {
            color: #ffffff;
          }
          span {
            &::before {
              background: $corp-color;
              border-color: #ffffff;
              color: $corp-color;
            }
          }
        }

        .completed {
          padding: 11px 0;
          background: #ffffff;
          @include media-breakpoint-down(sm) {
            padding: 20px;
          }
          span {
            color: #515050;
            &::before {
              background: $corp-color;
              border-color: $corp-color;
              color: #ffffff;
              padding: 2px 6px;
              font-size: 16px;
            }
          }
        }

        div {
          @include media-breakpoint-down(sm) {
            text-align: left;
            margin: 0 auto;
            padding: 20px;
          }
        }
      }
    }
  }


  .mcw_form_package_inner {
    width: 100%;
    display: block;
    padding: 40px 10px 75px 10px;
    margin-bottom: 10px;
    border-bottom: 1px solid rgba(81, 80, 80, 0.076);
    border-radius: $border-radius-default;

    box-shadow: 0 1px 10px 1px #ccc;

    margin-bottom: 50px;

    &:last-child {
      margin-bottom: 0px;
    }

    .mcw_form_package_img {
      img {
        max-width: 100%;
      }
    }

    &:after {
      content: "";
      display: block;
      height: 0;
      clear: both;
      visibility: hidden;
      zoom: 1;
    }

    h3 {
      font-size: 28px;
      padding-left: 13px;
      padding-bottom: 10px;
    }

    li {
      font-size: 0.9rem;
      list-style: none;

      &:before {
        content: '- ';
      }
    }

    input[type="radio"], input[type="checkbox"] {
      visibility: hidden;
      display: none;
    }

  }

  .mcw_offer {
    display: none;
    @include media-breakpoint-up(md) {
      margin: 0 40px;
      width: 89%;
      margin-bottom: 30px !important;
      margin-top: -30px;
    }

    @media (min-width: 768px) and (max-width: 1000px) {
      margin: 0 9px;
      width: 97%;
      margin-bottom: 30px !important;
      margin-top: -20px;
    }

    .mcw_form_small_answers {
      display: flex;
    }
  }

  .mcw_form {
    background-color: #ffffff;
    margin: 0px auto;
    padding: 40px;
    width: 100%;

    @include media-breakpoint-down(md) {
      padding: 5px;
      padding-bottom: 20px;
    }

    .mcw_form_package_wrapper {
      margin-top: -5px;

      > h2 {
        padding-bottom: 20px;
      }

    }

    .mcw_form_answers {
      position: relative;
      display: table;
      @include media-breakpoint-down(sm) {
        display: block;
      }
      @include media-breakpoint-down(md) {
        width: 100%;
      }

      .mcw_form_answer {
        display: inline-block;

        position: relative;
        margin-top: 20px;
        margin-right: 20px;
        @include media-breakpoint-down(md) {
          width: 100%;
        }

        label {
          cursor: pointer;
          position: relative;
          font-size: 1rem;
          padding: 40px 0px 30px 0px;
          width: 180px;
          text-align: center;
          border: 1px solid #e1e1e1;
          border-radius: $border-radius-default;
          font-weight: 500;
          color: #7d7c7c;
          float: left;

          -webkit-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          user-select: none;

          img {
            max-width: 70px;
            position: absolute;
            margin-left: auto;
            margin-right: auto;
            left: 0;
            right: 0;
            bottom: 38px;

            -webkit-filter: grayscale(100%);
            filter: grayscale(100%);
          }

          @include media-breakpoint-down(md) {
            padding: 50px 60px 20px 60px;
            width: 100%;
            text-align: center;
          }

          &:hover {
            img {
              -webkit-filter: grayscale(0%);
              filter: grayscale(0%);
            }
          }
        }

        input[type="radio"], input[type="checkbox"] {
          visibility: hidden;
          display: none;
        }

        input[type="radio"]:checked + label, input[type="checkbox"]:checked + label {
          background: #f9f9f9;
          border: 1px solid $corp-color;
          -webkit-box-shadow: 0px 0px 0px 1px rgba(247, 137, 47, 1);
          -moz-box-shadow: 0px 0px 0px 1px rgba(247, 137, 47, 1);
          box-shadow: 0px 0px 0px 1px rgba(247, 137, 47, 1);

          img {
            -webkit-filter: grayscale(0%);
            filter: grayscale(0%);
          }

          &::after {
            font-family: simple-line-icons, sans-serif;
            font-size: 18px;
            content: '\e080';

            position: absolute;

            background: $corp-color;
            color: #fff;
            padding: 3px 11px;
            right: 0px;
            top: 0px;
            border-radius: 0px 0 0 6px;
          }
        }
      }

      .mcw_form_info_box {
        position: absolute;
        top: 7px;
        left: 9px;
        background: #939393;
        color: #ffffff;
        padding: 0px 9px;
        border-radius: 30px;
        font-size: 14px;
        opacity: 0.5;

        .tooltiptext {
          visibility: hidden;
          width: 220px;
          background-color: #515050;
          color: #fff;
          text-align: center;
          border-radius: 6px;
          padding: 5px;
          position: absolute;
          z-index: 1;
          bottom: 190%;
          left: 50%;
          margin-left: -110px;

          @include media-breakpoint-down(md) {
            width: 380px;
            bottom: 150%;
            left: 610%;
          }

          &::after {
            content: "";
            position: absolute;
            top: 100%;
            left: 50%;
            margin-left: -5px;
            border-width: 5px;
            border-style: solid;
            border-color: #515050 transparent transparent transparent;
          }
        }

        &:hover {
          opacity: 1;
          .tooltiptext {
            visibility: visible;
          }
        }
      }

      .img-no-text {
        label {
          padding: 64px 0px 30px 0px;
        }
      }

      .img-no-text-last {
        padding-right: 40px;

        @include media-breakpoint-down(md) {
          padding-right: 0px;
        }
      }
    }

    .steps {
      display: flex;
      width: 100%;
      margin-top: 40px;
      margin-bottom: 20px;
      background-color: #ddd;
      z-index: 3;
      position: relative;

      .step {
        display: flex;
        flex-direction: row;
        flex-basis: auto;
        flex-wrap: nowrap;

        height: 4px;
        width: 100%;
        background-color: $corp-color;
        -webkit-transition: width 0.6s ease-in-out;
        -moz-transition: width 0.6s ease-in-out;
        -o-transition: width 0.6s ease-in-out;
        transition: width 0.6s ease-in-out;

      }

      .step.active {
        background-color: $corp-color;
        opacity: 1;
      }

      .step.finish {
        background-color: $corp-color;
      }
    }

    .mcw_form_label {
      display: none;
    }

    .tab {
      padding-bottom: 130px;
      height: 460px;

      @include media-breakpoint-down(md) {
        padding-bottom: inherit;
        height: inherit;
      }

      h2 {
        padding-bottom: 0px;
        margin-bottom: 0;
        font-size: 34px;
        @include media-breakpoint-down(xs) {
          font-size: 1.5rem;
        }
      }

      strong {
        display: block;
        padding-bottom: 20px;
        font-size: 21px;
      }
    }

    .mcw_form_buttons {
      float: right;
    }
  }

  .mcw_form_package {

    #mcw_package_menu {
      display: block;

    }

    #mcw_steps {
      display: none;
    }

    .tab {
      height: inherit;
      padding-bottom: inherit;
    }

    .mcw_form_small_answers {
      .mcw_form_answer {
        margin-right: 0px;
        label {
          padding: 0;
          text-align: left;
          border: transparent !important;
          box-shadow: none !important;
          width: 150px;

          @include media-breakpoint-down(md) {
            margin-right: 0;
          }
          &::before {
            font-family: simple-line-icons, sans-serif;
            font-size: 13px;
            content: '\E080';
            border-radius: 0 5px 0 5px;
            background: #e1e0e2;
            color: #e1e0e2;
            padding: 7px 8px;
            margin-right: 10px;
            margin-left: 0px;
          }
        }
      }

      .mcw_form_answer {
        @include media-breakpoint-down(md) {
          width: inherit;
        }
      }

      input[type="radio"]:checked + label {
        background: transparent !important;
        border: inherit;

        &::before {
          content: '\E080';
          background: #f7882f;
          color: #fff;
        }

        &::after {
          display: none;
        }
      }
    }

    .without_form_label {
      padding-left: 198px;
      padding-top: 10px;

      .mcw_form_info_box {
        top: 14px !important;
        right: -40px !important;
      }

      @include media-breakpoint-down(sm) {
        padding-left: 0px;
      }
    }

    .mcw_form_buttons {
      float: left;
      #nextBtn {
        display: none;
      }

      #prevBtn {
        display: none !important;
        line-height: inherit;
        height: inherit;
        color: inherit;
        background: inherit;
        padding: 0px;
        margin-top: 40px;
        opacity: 0.5;

        @include media-breakpoint-down(md) {
          margin-left: 0px;
        }

        &:hover {
          opacity: 1;
        }
      }
    }

    #mcw_form {
      .mcw-text-row {
        display: none;
      }
    }

    .steps {
      display: none;
    }
  }

  .mcw_form_all_answers {

    #mcw_package_menu {
      display: block;
    }

    #mcw_steps {
      display: none;
    }

    #mcw_form_steps {
      padding: 40px 0;
      padding-top: 0;

      .mcw_form_package_inner {
        display: none;
      }

      .mcw_form_package_wrapper {
        display: none;
      }

      .mcw_form_info_box {
        top: 0px !important;
        right: -30px !important;
      }

      .tab {
        display: flex !important;
        height: inherit;
        padding-bottom: 0;

        @include media-breakpoint-down(sm) {
          display: block !important;
        }

        > h2 {
          display: none;
        }

        .mcw_form_label {
          display: flex;
          flex-direction: column;
          justify-content: center;

          p {
            padding-top: 5px;
            font-size: 1em;
            margin-bottom: 0;
          }

          > div {
            text-align: center;
            width: 208px;
            position: relative;
            .fa {
              font-size: 30px;
              text-align: center;
              display: block;
            }

            .euro {
              font-family: $corp-font-family;
              font-weight: 500;
            }
            @include media-breakpoint-up(md) {
              .mcw_form_label_inner {
                position: absolute;
                margin-left: auto;
                margin-right: auto;
                left: 0;
                right: 0;
              }
            }

            @include media-breakpoint-down(sm) {
              width: 100%;
            }
          }

          @include media-breakpoint-down(sm) {
            display: block;
            padding-top: 20px;
          }
        }

        .mcw_form_answers {
          display: block;
          .mcw_form_answer {
            margin-top: 0;
          }
          label {
            width: 130px;
            padding: 25px 0px 25px 0px;
            font-size: 0.9rem;
            > img {
              width: 60px;
              bottom: 26px;
            }

            @include media-breakpoint-down(md) {
              width: 100%;
            }
          }

          input[type="radio"]:checked + label, input[type="checkbox"]:checked + label {
            &::after {
              font-size: 13px;
              padding: 3px 8px;
            }
          }
        }

        .img-no-text {
          label {
            padding: 40px 0px 30px 0px;
          }
        }

        .img-no-text-last {
          padding-right: 0px;
        }

        .without_form_label {
          padding-left: 198px;
          padding-top: 10px;

          .mcw_form_info_box {
            top: 14px !important;
            right: -40px !important;
          }

          @include media-breakpoint-down(sm) {
            padding-left: 0px;
          }
        }

        .mcw_form_small_answers {
          .mcw_form_answer {
            margin-right: 0px;
            label {
              padding: 0;
              text-align: left;
              border: transparent;
              box-shadow: none !important;
              width: 150px;

              @include media-breakpoint-down(md) {
                margin-right: 0;
              }
              &::before {
                font-family: simple-line-icons, sans-serif;
                font-size: 13px;
                content: '\E080';
                border-radius: 0 5px 0 5px;
                background: #e1e0e2;
                color: #e1e0e2;
                padding: 7px 8px;
                margin-right: 10px;
                margin-left: 10px;
              }

            }
          }

          .mcw_form_answer {
            @include media-breakpoint-down(md) {
              width: inherit;
            }
          }

          input[type="radio"]:checked + label {
            background: transparent;
            border: inherit;

            &::before {
              content: '\E080';
              background: #f7882f;
              color: #fff;
            }

            &::after {
              display: none;
            }
          }
        }

        .mcw_form_long_text {
          label {
            font-size: 0.8rem;
            width: -webkit-fill-available !important;

            &::before {
            }

          }
        }
      }

      .full_line {
        display: block;
        opacity: 0.2;
        margin-top: 1.5em;
        margin-bottom: 1.5em;
        margin-left: auto;
        margin-right: auto;

        border-style: inset;
        border-width: 1px;

      }

      .steps {
        display: none;
      }

      .mcw_form_buttons {
        float: left;
        #nextBtn {
          display: none;
        }

        #prevBtn {
          line-height: inherit;
          height: inherit;
          color: inherit;
          background: inherit;
          padding: 0px;
          margin-left: 55px;
          margin-top: 40px;
          opacity: 0.5;

          @include media-breakpoint-down(md) {
            margin-left: 0px;
          }

          &:hover {
            opacity: 1;
          }
        }
      }
    }
  }

  .mcw-quotation {
    background: #333;

    &:hover {
      background: #333;
      opacity: 0.8;
    }
  }

  #mcw_payment {
    padding: 34px;

    @include media-breakpoint-down(lg) {
      padding: 0;
      padding-right: 20px;
    }

    @include media-breakpoint-down(md) {
      padding: 0;
    }

    @include media-breakpoint-down(sm) {
      padding: 15px;
    }

    .mcw-text-row {
      padding: 0px 0px;

      @include media-breakpoint-down(md) {
        padding-top: 30px;
      }
    }

    .mcw_payment_list {
      background: #fbf9f9;
      border-radius: 0px 0px 5px 5px;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
      margin-bottom: 20px;
      .mcw_payment_list_head {
        padding: 10px;
        background: #515050;
        span {
          color: #fff;
          font-size: 1em;
          text-transform: uppercase;
          font-weight: 500;
        }
      }
      .mcw_payment_list_body {
        padding: 10px;
        min-height: 180px;
        table {
          width: 100%;

          #price {
            width: 67px;
            text-align: right;

            p:first-child {
              margin-top: 0px;
            }
          }

          strong {
            display: block;
            font-size: 1rem;
            color: #717070;
          }

          p {
            margin-bottom: 5px;
          }
        }
      }
    }
    .cvz-mcw-payment-total-price {
      table {
        width: 100%;
        td#total-price {
          text-align: right;
        }

        .total-price-description {
          display: block;
          font-size: 0.7rem;
        }
      }

      .price {
        margin-bottom: 5px;
      }
    }

    .show {
      display: block;
    }

    .cvz-mcw-payment-button-container {
      .cvz-btn {
        margin-top: 15px;
        width: 100%;
      }

      .address-loader {
        border: 1px solid rgba(0, 0, 0, 0.2);
        border-left-color: #ffffff;
        -webkit-animation: loader-spin 1.2s infinite linear;
        animation: loader-spin 1.2s infinite linear;
        vertical-align: top;
        border-radius: 50%;
        width: 2em;
        height: 2em;
        margin: 0 auto;
        position: inherit;
        display: block;
      }

      p {
        color: #FF0000;
        text-align: center;
        margin-top: 10px;
      }
    }

    .cvz-mcw-payment-option {
      margin-top: 20px;

      .cvz-btn {
        font-size: 0.8rem;
        margin-top: 15px;
        width: 100%;
      }

      .address-loader {
        border: 1px solid rgba(0, 0, 0, 0.2);
        border-left-color: #ffffff;
        -webkit-animation: loader-spin 1.2s infinite linear;
        animation: loader-spin 1.2s infinite linear;
        vertical-align: top;
        border-radius: 50%;
        width: 2em;
        height: 2em;
        margin: 0 auto;
        position: inherit;
        display: block;
      }

      label {
        padding: 0;
        text-align: left;
        border: transparent;
        margin-right: 20px;
        margin-top: 10px;

        @include media-breakpoint-down(md) {
          margin-right: 0;
        }
        &::before {
          font-family: simple-line-icons, sans-serif;
          font-size: 16px;
          content: '\E080';
          border-radius: 0 5px 0 5px;
          background: #ffffff;
          color: #ffffff;
          padding: 6px 7px;
          margin-right: 20px;
          border: 2px solid #c0c0c0;
        }
      }

      input[type="radio"] {
        display: none;
      }

      input[type="radio"]:checked + label {
        background: transparent;
        border: inherit;

        &::before {
          content: '\E080';
          background: #f7882f;
          color: #fff;
          border: 2px solid #f7882f;
        }

        &::after {
          display: none;
        }
      }

      p {
        color: #FF0000;
        text-align: center;
        margin-top: 10px;
      }

      strong {
        font-weight: 600;
        display: block;
        margin-bottom: 5px;
      }
    }

  }
}

#mcw_form_information, #get_available_slots, #forgot_password, #change_password, #planning-customer-information, #cvz-update-relation {
  padding: 0 30px;
  @include media-breakpoint-down(md) {
    padding: 0 10px;
  }
  .mcw_form_information_container {
    textarea:focus, input:focus {
      outline: none !important;
    }
  }

  .mcw_form_long {
    width: 100%!important;
  }

  .mcw-text-row {
    padding: 0;
    padding-top: 5px;
    padding-bottom: 5px;
  }
  .mcw_form_gender {
    > label {
      display: block;
      text-transform: uppercase;
      color: #000;
      font-weight: 500;
      font-size: 0.9rem;
      padding-bottom: 5px;
      span {
        color: $corp-color;
      }
    }
    .mcw_form_gender_option {
      display: inline-block;
      label {
        padding: 0;
        text-align: left;
        border: transparent;
        margin-right: 20px;

        @include media-breakpoint-down(md) {
          margin-right: 0;
        }
        &::before {
          font-family: simple-line-icons, sans-serif;
          font-size: 16px;
          content: '\E080';
          border-radius: 0 5px 0 5px;
          background: #ffffff;
          color: #ffffff;
          padding: 6px 7px;
          margin-right: 20px;
          border: 2px solid #c0c0c0;
        }
      }

      input[type="radio"] {
        display: none;
      }

      input[type="radio"]:checked + label {
        background: transparent;
        border: inherit;

        &::before {
          content: '\E080';
          background: #f7882f;
          color: #fff;
          border: 2px solid #f7882f;
        }

        &::after {
          display: none;
        }
      }
    }
  }

  .mcw_form_name, .mcw_form_adres_place, .mcw_form_password {
    display: inline-block;
    width: 100%;
    .mcw_form_name_row, .mcw_form_adres_place_row, .mcw_form_password_row {
      width: 50%;
      float: left;
      padding: 15px 0px;
      > label {
        display: block;
        text-transform: uppercase;
        color: #000;
        font-weight: 500;
        font-size: 0.9rem;
        padding-bottom: 5px;
        span {
          color: $corp-color;
        }
      }

      input {
        width: 90%;
        padding: 10px;
        margin-right: 30px;
        border: 1px solid #e1e0e2;
        border-radius: $border-radius-default;
      }
    }
  }

  .mcw_form_adres_house {
    display: block;
    .mcw_form_adres_house_row {
      width: 125px;
      display: inline-block;
      margin-right: 30px;

      label {
        display: block;
        text-transform: uppercase;
        color: #000;
        font-weight: 500;
        font-size: 0.9rem;
        padding-bottom: 5px;

        span {
          color: $corp-color;
        }
      }
      input {
        width: 100%;
        padding: 10px;
        border: 1px solid #e1e0e2;
        border-radius: $border-radius-default;
      }
    }
  }

  .mcw_form_comments {
    margin-bottom: 20px;
    label {
      display: block;
      text-transform: uppercase;
      color: #000;
      font-weight: 500;
      font-size: 0.9rem;
      padding-bottom: 5px;

    }

    textarea {
      width: 95%;
      border: 1px solid #e1e0e2;
      border-radius: $border-radius-default;
      resize: none;

    }
  }

  @include media-breakpoint-down(xs) {
    .mcw_form_information_container {
      .mcw_form_name_row, .mcw_form_adres_place_row {
        width: 100%;
        input {
          width: 100%;
          margin-right: 0px;
        }
      }
    }
  }

  .mcw_form_validate.has-success {
    input {

    }

    label {
      &:before {
      }
    }
  }

  .mcw_form_validate.has-error {
    input {
      border-color: red;
    }
    label {
      &:before {
        border-color: red;
      }
    }
  }
}

.mcw_order_check {

  .cvz-btn-black {
    float: right;
    &:last-child {
      margin-bottom: 40px;
    }
  }

  .mcw_order_check_container {
    padding: 0 30px;

    @include media-breakpoint-down(md) {
      padding: 0 10px;
    }

    > div {
      padding: 5px 0;
    }

    label {
      text-transform: uppercase;
      color: #000;
      font-weight: 500;
      font-size: 0.9rem;

      padding-bottom: 5px;
    }

    strong {
      font-weight: 600;
    }

    .mcw_order_check_package {
      width: 100%;
      display: block;
      //padding: 10px;
      //border-radius: 0px 5px 0 5px;
      //border: 1px solid #f7882f;
      margin-top: 20px;
      margin-bottom: 20px;

      p {
        margin-bottom: 0;
      }

      .mcw_payment_list_body {
        table {
          width: 100%;

          #price {
            width: 67px;
          }
        }
      }
    }

    .mcw_order_check_information {
      width: 100%;
      display: block;
      //padding: 10px;
      //border-radius: 0px 5px 0 5px;
      //border: 1px solid #f7882f;
      margin-top: 20px;
      margin-bottom: 20px;

      p {
        margin-bottom: 0;
      }
    }
  }
}

.mcw_complete {
  .cvz-mcw-col-inner {
    text-align: center;
    padding: 130px 15px;
  }
}

.loading-screen {
  position: absolute;

  top: 0;
  left: 0;

  width: 100%;
  height: 100%;

  background-color: white;
  text-align: center;

  display: none;

  z-index: 2;
}

.loading-screen .loading-screen-inner {
  display: flex;

  flex: 1;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  width: 100%;
  height: 100%;

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  .checkmark-list {
    text-align: left;
    padding: 25px;

    h2 {
      margin-bottom: 40px;
    }
    .checkmark-list-inside {
      .checkmark-text {
        display: inline-block;
        line-height: 0px;
        padding-left: 20px;
      }
    }
  }

  .loading-flame {
    .static-circle {
      border: none;
      width: 5em;
      height: 5em;
      margin-right: 10px;
      padding: 17px;
      margin-top: 10px;
      top: -120px;

      .static-checkmark {
        height: 5em;
        width: 2.5em;
        left: 0.1em;
        top: 3em;
        border-right: 6px solid #f7882f;
        border-top: 6px solid #f7882f;
      }
    }
  }
}

.loading-screen .loading-screen-inner img {
  //transform: translateY(100px);
  //animation: move 2s ease-in-out 0.5s infinite;
  //-moz-animation: move 2s ease-in-out 0.5s infinite;
  //-webkit-animation: move 2s ease-in-out 0.5s infinite;

  width: 110px;
  margin-top: -210px;
  filter: grayscale(1);
  animation: grayscale 1s;
  -moz-animation: grayscale 1s infinite;
  -webkit-animation: grayscale 1s infinite;
}

.loading-between {
}

.loading-end {
  max-height: 600px;
  min-height: 600px;
}

@keyframes move {
  0% {
    transform: translateY(100px);
  }
  50% {
    transform: translateY(0px);
  }
  100% {
    transform: translateY(100px);
  }
}

@-moz-keyframes grayscale {
  0% {
    filter: grayscale(1);
  }
  50% {
    filter: grayscale(0);
  }
  100% {
    filter: grayscale(1);
  }
}

@-webkit-keyframes grayscale {
  0% {
    filter: grayscale(1);
  }
  50% {
    filter: grayscale(0);
  }
  100% {
    filter: grayscale(1);
  }
}

.circle-loader {
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-left-color: $corp-color;
  animation: loader-spin 1.2s infinite linear;
  position: relative;
  display: inline-block;
  vertical-align: top;
  border-radius: 50%;
  width: $loader-size;
  height: $loader-size;
}

.circle-sub-loader {

  border: 1px solid rgba(0, 0, 0, 0.2);
  border-left-color: $corp-color;
  animation: loader-spin 1.2s infinite linear;
  position: relative;
  display: inline-block;
  vertical-align: top;
  border-radius: 50%;
  width: 13em;
  height: 13em;
  margin-top: -102px;
  margin-left: -6px;
  position: absolute;
  top: 30%;
}

.loading-start {
  h2 {
    position: relative;
    bottom: -120px;
  }

  .circle-sub-loader {
    top: 39%;
  }
}

.address-loader {
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-left-color: $corp-color;
  animation: loader-spin 1.2s infinite linear;
  position: relative;
  display: inline-block;
  vertical-align: top;
  border-radius: 50%;
  width: 1.6em;
  height: 1.6em;
  margin-top: 45px;
  margin-left: -16px;
  position: absolute;
}

.load-complete {
  -webkit-animation: none;
  animation: none;
  border-color: $corp-color;
  transition: border 500ms ease-out;
}

.checkmark {
  display: none;

  &.draw:after {
    animation-duration: 800ms;
    animation-timing-function: ease;
    animation-name: checkmark;
    transform: scaleX(-1) rotate(135deg);
  }

  &:after {
    opacity: 1;
    height: $check-height;
    width: $check-width;
    transform-origin: left top;
    border-right: $check-thickness solid $corp-color;
    border-top: $check-thickness solid $corp-color;
    content: '';
    left: $check-left;
    top: $check-height;
    position: absolute;
  }
}

.mcw-modal {
  .static-checkmark {
    animation: checkmark-static 4s infinite linear;
  }
}

@keyframes loader-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes checkmark {
  0% {
    height: 0;
    width: 0;
    opacity: 1;
  }
  20% {
    height: 0;
    width: $check-width;
    opacity: 1;
  }
  40% {
    height: $check-height;
    width: $check-width;
    opacity: 1;
  }
  100% {
    height: $check-height;
    width: $check-width;
    opacity: 1;
  }
}

@keyframes checkmark {
  0% {
    height: 0;
    width: 0;
    opacity: 1;
  }
  20% {
    height: 0;
    width: $check-width;
    opacity: 1;
  }
  40% {
    height: $check-height;
    width: $check-width;
    opacity: 1;
  }
  100% {
    height: $check-height;
    width: $check-width;
    opacity: 1;
  }
}

@keyframes checkmark-static {
  0% {
    height: 0;
    width: 0;
    opacity: 1;
  }
  20% {
    height: 0;
    width: $check-width-static;
    opacity: 1;
  }
  40% {
    height: $check-height-static;
    width: $check-width-static;
    opacity: 1;
  }
  100% {
    height: $check-height-static;
    width: $check-width-static;
    opacity: 1;
  }
}