@import "../base";
@import "../_includes/mixins";

header {
  &.header-overlay {

    width: 100%;

    @include media-breakpoint-up(lg) {
      position: absolute;
      top: 52px;
    }

  }

  .header-wrapper {
    position: relative;
    width: 100%;
    z-index: 9999;
  }

  .header-inner {
    display: flex;
    flex-direction: column;

    width: 100%;

    background: #ffffff;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
    @include media-breakpoint-up(lg) {
      padding-bottom: 1rem;
    }

    @include media-breakpoint-up(lg) {
      @include cvz_border_radius(false, false, false, true);

      flex-direction: column;
      padding-bottom: 0;
    }

    .top-bar {
      background-color: $corp-secondary-color;
      display: none;
      padding: 5px 0px;

      @include media-breakpoint-down(md) {
        display: flex;
      }

      .my-account {
        float: left;
        margin-bottom: 0px;

        a {
          color: white!important;
          i {
            margin-left: 0;
            margin-right: 0.5rem;
          }
        }
      }

      .nav-toggle {
        display: flex;
        align-items: center;

        float: right;

        margin-left: 1.25rem;

        span {
          font-family: 'simple-line-icons';
          display: inline-block;
          cursor: pointer;

          background-size: 100%;
          color: #ffffff;

          &:after {
            content: "\e601";
          }
        }
      }
    }

    .top {
      display: flex;
      height: 120px;

      padding-top: 10px;
      padding-bottom: 10px;

      height: 120px;

      padding-top: 10px;
      padding-bottom: 10px;


      @include media-breakpoint-down(lg) {
        height: 110px;
        padding-bottom: 0px;
      }

      .logo {
        padding: 10px 0;

        img {
          max-height: 100%;
          max-width: 100%;
          height: 80px;

          height: 80%;
          max-width: 230px;
          width: auto;

          //max-height: 58px;

          @include media-breakpoint-up(lg) {
            //margin-left: 25px;
          }

          @include media-breakpoint-up(xl) {
            max-height: 100%;
          }

        }
      }

      .right {
        display: flex;
        flex: 1;
        flex-direction: column;

        justify-content: flex-end;
        align-items: flex-end;

        .search {
          form {
            width: 300px;
            position: relative;

            .ion-search {
              position: absolute;
              left: 0px;
              padding: 9px 12px;
              pointer-events: none;
              color: #828482;
            }

            input {
              padding: 10.5px 15px;
              padding-left: 30px;
              margin-bottom: 10px;
              font-size: .875rem;
              width: 100%;
              border: 1px solid #e1e0e2;
              background-color: #ffffff;
              color: #515050;
              border-radius: 0 5px 0 5px;

              &:focus {
                outline: 0;
              }
            }
          }

          @include media-breakpoint-down(sm) {
            display: none;
          }
        }

        @include media-breakpoint-down(md) {
          .my-account {
            display: none;
          }
        }
      }
    }

    .bottom {
      background-color: $corp-secondary-color;

      @include media-breakpoint-down(md) {
        background-color: #ffffff;
        display: none;
        position: absolute;
        left: 0;
        top: 152px;
        width: 100%;
        padding-bottom: 20px;
      }

      nav {
        position: relative;

        @include media-breakpoint-up(md) {
          display: flex;
        }

        > ul {
          position: relative;
          display: flex;
          list-style: none;
          width: 100%;
          margin: 0;
          padding: 0;

          font-family: $corp-font-family;
          font-weight: 500;
          font-size: 1rem;

          @include media-breakpoint-down(md) {
            display: block;
            overflow-y: scroll;
            max-height: 390px;
          }

          > li {
            display: flex;
            align-items: center;

            @include media-breakpoint-down(md) {
              justify-content: center;
            }

            @include media-breakpoint-up(lg) {
              &.menu-item-has-children {
                &:hover {
                  &::after {
                    position: absolute;
                    content: ' ';
                    width: 100%;
                    height: 5px;

                    top: 100%;
                    left: 0;

                    background: rgba(255, 255, 255, .6);

                  }

                  > a {
                    &::after {
                      position: absolute;
                      content: ' ';

                      top: 100%;
                      left: 0;
                      width: 100%;
                      height: 5px;
                      background-color: #ffffff;
                    }
                  }
                }
              }
            }

            > a {
              display: inline-block;
              position: relative;

              padding: .5rem 0;
              text-transform: uppercase;

              @include media-breakpoint-up(md) {
                padding: 1rem;
              }

              color: white;

              @include media-breakpoint-down(md) {
                color: $color-dark-grey;
              }

              @include cvz_transition_link();

              //&:hover {
              //  color: $navigation-link-hover-color;
              //  background-color: #ffffff;
              //}
            }

            > ul {
              display: none;
              position: absolute;
              top: 100%;
              left: 0;
              width: 100%;
              background-color: #ffffff;

              box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.3);

              margin-top: 5px;

              justify-content: space-between;

              padding: 1.25rem 2.75rem 2.5rem 2.75rem;

              @include media-breakpoint-up(md) {
                min-height: 378px;
              }

              @include cvz_border_radius(false, false, true, true);

              z-index: 99;

              &.nav-with-info-block {
                > li {
                  &:not(.nav-info-block) {
                    flex: 2;

                    > ul {
                      display: flex;
                      flex-wrap: wrap;
                      list-style: none;
                      padding: 0;
                      margin: 0;

                      .nav-link-text {
                        a {
                          color: rgb(81, 80, 80);
                          padding: 0;

                          @include cvz_transition_link;

                          &:hover {
                            color: $corp-color;
                          }
                        }
                      }

                      > li {
                        display: flex;
                        flex: 1;
                        flex-basis: 50%;
                        flex-direction: column;
                        text-align: left;
                        font-weight: 600;
                        line-height: 37px;

                        @include media-breakpoint-down(sm) {
                          flex-basis: 100%;
                          text-align: center;
                        }

                        ul {
                          list-style: none;
                          margin: 0;
                          padding: 0;
                          text-align: left;

                          li {
                            padding: 0;

                            @include media-breakpoint-down(sm) {
                              text-align: center;
                            }

                            a {
                              padding: 0;
                              font-weight: normal;
                              color: $body-color;

                              @include cvz_transition_link;

                              &:hover {
                                color: $corp-color;
                              }
                            }
                          }
                        }
                      }
                    }
                  }

                  text-align: left;

                  &.nav-info-block {
                    flex: 1;

                    border-left: 1px solid $border-color;

                    @include media-breakpoint-up(md) {
                      min-width: 360px;
                    }

                    @include media-breakpoint-down(sm) {
                      display: none;
                    }

                    > div {
                      padding: 0 60px;

                      &.menu-info-block-contact {
                        display: flex;
                        flex-direction: column;
                        font-weight: normal;

                        line-height: 24px;

                        .title {
                          font-weight: 600;
                          line-height: 37px;
                        }

                        .map {
                          margin: .5rem 0 1rem 0;

                          img {
                            max-width: 100%;
                          }
                        }

                        .name {
                          color: $corp-secondary-color;
                          font-weight: 600;
                        }

                        .address {
                          margin-bottom: 1rem;
                          font-size: 15px;

                          span {

                            &:first-child {
                              display: block;
                            }
                          }
                        }

                        > div {
                          > span {
                            display: block;

                            &.contact-item {
                              position: relative;

                              span {
                                display: inline-block;

                                position: absolute;

                                left: 50px;
                              }
                            }
                          }
                        }
                      }

                      &.menu-info-block-most-sold-boiler {
                        display: flex;
                        flex-direction: column;
                        font-weight: normal;
                        padding: 0 20px;

                        line-height: 24px;

                        img {
                          max-width: 100%;
                        }

                        h5 {
                          &:first-child {
                            color: $corp-color;
                          }
                        }

                        .photo {
                          display: flex;
                          margin: .5rem 0 1rem 0;

                          .left {
                            position: relative;

                            img {
                              width: auto;
                              max-width: inherit;
                              height: 180px;
                            }

                            .discount-stamp {
                              position: absolute;
                              display: flex;
                              background: #ee3a43;
                              color: #ffffff;
                              border-radius: 180px;

                              width: 70px;
                              height: 70px;

                              justify-content: center;
                              align-items: center;
                              flex-direction: column;

                              span {

                                &:first-child {
                                  text-decoration-line: line-through;
                                  font-size: 1.1rem;
                                }
                              }
                            }
                          }

                          .right {
                            width: 150px;
                            flex-direction: column;
                            display: flex;

                            .most-sold-red {
                              color: #ee3a42;
                              font-size: 14px;

                              &:last-child {
                                &:before {
                                  padding-right: 29px;
                                }
                              }
                            }

                            .plus {
                              text-align: center;
                              padding-left: 18px;
                            }

                            .img {
                              &:before {
                                font-family: FontAwesome, "Open Sans", Arial, sans-serif;
                                content: '\f2cb';
                                color: $color-dark-grey;
                                padding-right: 15px;
                              }

                              img {
                                max-width: 100px;
                              }
                            }

                            span {
                              font-weight: 500;
                              max-width: 100%;

                              &:first-child {
                                &:before {
                                  font-family: FontAwesome, "Open Sans", Arial, sans-serif;
                                  content: '\f095';
                                  color: $color-dark-grey;
                                  padding-right: 30px;
                                }
                              }

                              &:last-child {
                                &:before {
                                  font-family: FontAwesome, "Open Sans", Arial, sans-serif;
                                  content: '\f0ad';
                                  color: $color-dark-grey;

                                  padding-right: 13px;
                                }
                              }
                            }
                          }
                        }

                        .quotation-call {
                          float: right;
                          background: $corp-color;

                          font-weight: 500;
                          text-transform: none;

                          &:hover {
                            background: $corp-color;
                            opacity: 0.8;
                          }
                        }
                      }

                      &.menu-info-block-plan-maintenance, &.menu-info-block-boiler-wizard {
                        display: flex;
                        flex-direction: column;
                        font-weight: normal;
                        padding: 0 20px;

                        line-height: 24px;

                        justify-content: center;
                        height: 90%;

                        >div {
                          box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
                          padding: 50px 25px;
                          width: 340px;
                          margin-left: 13px;

                          img {
                            max-width: 100%;
                          }

                          h5 {
                            &:first-child {
                              color: $corp-color;

                              margin-bottom: 20px;
                            }
                          }

                          >span {
                            font-size: 16px;
                            margin-bottom: 20px;
                          }

                          a {
                            background: #f7882f;
                            color: #ffffff;
                            padding: 5px 10px;
                            font-size: 14px;
                            font-weight: 600;
                            line-height: 32px;
                            margin-top: 30px;

                            &:hover {
                              opacity: 0.8;
                            }
                          }

                          form {
                            display: flex;

                            justify-content: center;
                            margin-top: 62px;
                            margin-bottom: -2px;

                            >a {
                              background: transparent;
                              display: flex;
                              line-height: inherit;
                              width: 320px;
                              margin-top: -10px;

                              &:hover {
                                opacity: 1;
                              }
                            }

                            input[type="text"] {
                              border-radius: 0 0 0 5px;
                              outline: 0;
                              background-color: transparent;
                              color: $body-color;
                              padding: .3rem .5rem;
                              width: 300px !important;
                              border-right: 0;
                            }

                            input,
                            button {
                              margin: 0;
                              padding: 0;
                              border: 1px solid $color-grey;
                              font-size: .875rem;
                            }

                            input[type="submit"],
                            button[type="submit"] {
                              border-radius: 0 5px 0 0;

                              @include cvz_transition_bg();

                              outline: 0;
                              background-color: $corp-color;
                              border-color: $corp-color;
                              color: #ffffff;
                              font-weight: 500;
                              text-transform: uppercase;
                              padding: .3rem .5rem;
                              line-height: 1;
                              cursor: pointer;

                              &:hover {
                                background-color: rgba($corp-color, 0.9);
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }

              > li {
                flex: 1;
                display: flex;
                flex-direction: column;
                text-align: center;

                a {
                  padding: 0 .75rem;

                  @include media-breakpoint-up(xl) {
                    padding: 0 2.5rem;
                  }

                  span {
                    display: block;

                    &.nav-link-image {
                      img {
                        height: 130px;
                      }
                    }

                    &.nav-link-text {
                      font-size: 1.2rem;
                      font-weight: 600;

                      color: $corp-color;

                      @include media-breakpoint-up(lg) {
                        margin-top: 1rem;
                      }
                    }

                    &.nav-link-description {
                      font-size: 1rem;
                      font-weight: 300;
                      color: $body-color;

                      margin: 1rem 0 2rem 0;
                      line-height: 1.75rem;
                    }

                    &.nav-link-button {
                      display: inline-block;
                      width: auto;
                      text-transform: none;
                    }
                  }

                  &:hover {
                    .nav-link-button {
                      background-color: $corp-color;
                      color: #ffffff;

                      &:after {
                        display: block;
                      }
                    }
                  }
                }
              }
            }
            @include media-breakpoint-up(lg) {
              &.open {
                &.menu-item-has-children {
                  &::after {
                    position: absolute;
                    content: ' ';
                    width: 100%;
                    height: 5px;

                    top: 100%;
                    left: 0;

                    background: rgba(255, 255, 255, .6);
                  }

                  > a {
                    &::after {
                      position: absolute;
                      content: ' ';

                      top: 100%;
                      left: 0;
                      width: 100%;
                      height: 5px;
                      background-color: #ffffff;
                    }
                  }
                }

                > a {
                  color: $navigation-link-hover-color;
                  background-color: #ffffff;
                }

                > ul {
                  display: flex;
                }
              }
            }
          }
        }
      }

    }

    .my-account {
      @include media-breakpoint-down(md) {
        margin-bottom: 20px;
      }
      a {

      }
    }
  }
}

.nav-toggle-active {
  .nav-toggle {
    span {
      position: relative;
      right: -2px;
      &:after {
        font-family: $font-family-base;
        content: "+" !important;
        display: inline-block;
        font-size: 35px;
        line-height: 23px;

        transform: rotate(-45deg);
      }
    }
  }

  header {
    .header-inner {
      @include media-breakpoint-down(md) {
        .bottom {
          display: block;
          box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.3);
          ul {
            .menu-item-has-children {
              > a {
                &:after {
                  font-family: simple-line-icons;
                  content: '\e604';
                  width: 12px;
                  margin-top: -5px;
                }
              }
            }

            .menu-item-has-children.open {
              flex-direction: column;

              > a {
                &:after {
                  font-family: simple-line-icons;
                  content: '\e607';
                }
              }

              .nav {
                position: relative;
                display: flex;
                flex-direction: column;
                box-shadow: none;
                padding: 0 1rem;
                left: 0px;
                background: transparent;

                .nav-link-image {
                  display: none !important;
                }

                .nav-link-description {
                  color: $color-dark-grey !important;
                }

                .nav-link-button {
                  display: none !important;
                }
              }
            }
          }
        }
      }
    }
  }
}
